import CryptoJS from "crypto-js";

let actualKey = "*Ezy@PayBillPay*"; //'*Ezy@PayBillPay*',*Kai@zenvegitables234*;
let key = CryptoJS.enc.Utf8.parse(actualKey);
let iv = CryptoJS.enc.Utf8.parse(actualKey);

export const SecurityServices = {
  encryptFun,
  decryptFun,
};

function encryptFun(jsonStr: string) {
  let encrypted = CryptoJS.AES.encrypt(jsonStr, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  var encryptedString = CryptoJS.format.OpenSSL.stringify(encrypted);
  return encryptedString;
}

function decryptFun(resp: string) {
  let decryptedResponse = CryptoJS.AES.decrypt(resp, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return decryptedResponse.toString(CryptoJS.enc.Utf8);
}
