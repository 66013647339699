import { useTranslation } from "react-i18next";

export const GetMenuItemTranslation = (itemName: string) => {
  const { t } = useTranslation("mainMenuList");
  const translations: Record<string, { text: string; icon: string }> = {
    "User Registration": {
      text: t("User Registration"),
      icon: "MainMenu/user-registration-icon.svg",
    },
    "Applicant Registration": {
      text: t("Applicant Registration"),
      icon: "MainMenu/applicant-registration-icon.svg",
    },
    "Question Bank": {
      text: t("Question Bank"),
      icon: "MainMenu/question-bank-icon.svg",
    },
    Dashboard: {
      text: t("Dashboard"),
      icon: "MainMenu/dashboard-icon.svg",
    },
    Scheme: {
      text: t("Scheme"),
      icon: "MainMenu/scheme-icon.svg",
    },
    Report: {
      text: t("Report"),
      icon: "MainMenu/report-icon.svg",
    },
    Master: {
      text: t("Master"),
      icon: "MainMenu/master-icon.svg",
    },
    Setting: {
      text: t("Setting"),
      icon: "MainMenu/setting-icon.svg",
    },
    "AI File Upload": {
      text: t("AI File Upload"),
      icon: "MainMenu/AI-File-upload.svg",
    },
    Logout: {
      text: t("Logout"),
      icon: "MainMenu/logout-icon.svg",
    },
  };
  const translation = translations[itemName] || { text: itemName, icon: "" };
  return translation;
};

export const GetSubMenuItemTranslation = (itemName: string) => {
  const { t } = useTranslation("subMenuList");
  const translations: Record<string, { text: string; icon: string }> = {
    "Add Scheme": {
      text: t("Add Scheme"),
      icon: "MainMenu/add-scheme-icon.svg",
    },
    "Scheme Report": {
      text: t("Scheme Report"),
      icon: "MainMenu/scheme-report-icon.svg",
    },
    "Add Applicant": {
      text: t("Add Applicant"),
      icon: "MainMenu/add-applicant-icon.svg",
    },
    "Applicant Report": {
      text: t("Applicant Report"),
      icon: "MainMenu/applicant-report-icon.svg",
    },
    "User Report": {
      text: t("User Report"),
      icon: "MainMenu/user-report-icon.svg",
    },
    "Redirect User Report": {
      text: t("Redirect User Report"),
      icon: "MainMenu/redirect-icon.svg",
    },
    "Sms Report": {
      text: t("Sms Report"),
      icon: "MainMenu/sms-report-icon.svg",
    },
    "Key Setting": {
      text: t("Key Setting"),
      icon: "MainMenu/key-setting-icon.svg",
    },
    "Add Category": {
      text: t("Add Category"),
      icon: "MainMenu/add-category-icon.svg",
    },
    "Add Services": {
      text: t("Add Services"),
      icon: "MainMenu/add-service-icon.svg",
    },
    "Add Testimonial": {
      text: t("Add Testimonial"),
      icon: "MainMenu/add-testimonial-icon.svg",
    },
    "Add Question": {
      text: t("Add Question"),
      icon: "MainMenu/question-bank-icon.svg",
    },
    "Add Answer": {
      text: t("Add Answer"),
      icon: "MainMenu/add-answer-icon.svg",
    },
    "Message Setting": {
      text: t("Message Setting"),
      icon: "MainMenu/message-setting-icon.svg",
    },
    "SMS Setting": {
      text: t("SMS Setting"),
      icon: "MainMenu/sms-setting-icon.svg",
    },
    "Answer Report": {
      text: t("Added Answer Report"),
      icon: "MainMenu/added-answer-report.svg",
    },
  };
  const translation = translations[itemName] || itemName;
  return translation;
};
