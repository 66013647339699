import {
  PostAPI,
  PostAPIWithoutRequestAndKeyData,
  PostAPIWithoutKeydata,
} from "../helper/apiHelper";

export const INSERT = async (request: string) => {
  return PostAPI(request, "SchemeMaster/Insert");
};
export const DELETE = async (request: string) => {
  return PostAPI(request, "SchemeMaster/Delete");
};
export const UPDATE = async (request: string) => {
  return PostAPI(request, "SchemeMaster/Update");
};
export const SELECT = async (request: string) => {
  return PostAPI(request, "SchemeMaster/Select");
};
export const SELECT_BY_ID = async (request: string) => {
  return PostAPIWithoutKeydata(request, "SchemeMaster/SelectByID");
};
export const SelectByIDForEdit = async (request: string) => {
  return PostAPI(request, "SchemeMaster/SelectByIDForEdit");
};
export const SelectByCategoryID = async (request: string) => {
  return PostAPI(request, "SchemeMaster/SelectByCategoryID");
};
export const ServiceMasterDropdown = async () => {
  return PostAPIWithoutRequestAndKeyData("SchemeMaster/ServiceMasterDropdown");
};
export const SelectLastFourScheme = async () => {
  return PostAPIWithoutRequestAndKeyData("SchemeMaster/SelectLastFourScheme");
};
export const SchemeReport = async (request: string) => {
  return PostAPI(request, "SchemeMaster/SchemeReport");
};
export const SchemeReportByID = async (request: string) => {
  return PostAPI(request, "SchemeMaster/SelectSchemeByID");
};