import {
  Box,
  Button,
  FormControlLabel,
  Switch,
  Typography,
  TextField,
  Grid,
  MenuItem,
  IconButton,
  Menu,
  ToggleButtonGroup,
  ToggleButton,
  Modal,
  makeStyles,
} from "@mui/material";

import Moment from "moment";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import React, { useEffect, useRef, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import SettingsIcon from "@mui/icons-material/Settings";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import DownloadIcon from "@mui/icons-material/Download";
import styles from "../../uiElements/table/Table.module.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import {
  PROCESS,
  APPROVED,
  REJECTED,
  PENDING,
  SUCCESS,
  FAILURE,
  COMPLETED,
} from "src/component/helper/statusType";
export const dateView = (
  date: Date,
  format: string = "DD-MMM-yyyy hh:mm:ss A"
) => {
  Moment.locale("en");
  return Moment(date).format(format);
};

export const numberView = (value: number = 0) => (
  <Typography component="div" sx={{ flex: 1, textAlign: "right" }}>
    {new Intl.NumberFormat("en-IN", {
      minimumFractionDigits: 2,
    }).format(value)}
  </Typography>
);

const successBox = (msg: string) => (
  <>
    <Button
      variant="contained"
      style={{
        padding: "2px 10px",
        color: "white",
        backgroundColor: "#42B86F",
      }}
    >
      {msg}
    </Button>
  </>
);
const errorBox = (msg: string) => (
  <>
    <Button
      variant="contained"
      style={{
        padding: "2px 10px",
        color: "white",
        backgroundColor: "red",
      }}
    >
      {msg}
    </Button>
  </>
);
const warningBox = (msg: string) => (
  <>
    <Button
      variant="contained"
      style={{
        padding: "2px 10px",
        color: "white",
        backgroundColor: "#F58220",
      }}
    >
      {msg}
    </Button>
  </>
);

const DeliveryStatusWarningBox = (msg: string) => (
  <>
    <Button
      variant="contained"
      style={{
        padding: "2px 10px",
        color: "white",
        backgroundColor: "#F58220",
        width: "170px",
      }}
    >
      {msg}
    </Button>
  </>
);

const DeliveryStatusErrorBox = (msg: string) => (
  <>
    <Button
      variant="contained"
      style={{
        padding: "2px 10px",
        color: "white",
        backgroundColor: "red",
        width: "170px",
      }}
    >
      {msg}
    </Button>
  </>
);

const DeliveryStatusSuccessBox = (msg: string) => (
  <>
    <Button
      variant="contained"
      style={{
        padding: "2px 10px",
        color: "white",
        backgroundColor: "#42B86F",
        width: "170px",
      }}
    >
      {msg}
    </Button>
  </>
);

export const customTxnStatus = (row: any) => {
  let value = row?.original["Status"]!;

  if (value === SUCCESS || value === APPROVED || value === COMPLETED)
    return successBox(SUCCESS);
  else if (value === FAILURE || value === REJECTED) return errorBox(FAILURE);
  else if (value === PROCESS || value === PENDING) return warningBox(PROCESS);
  else return warningBox(PROCESS);
};

export const srNo = (row: any, table: any) => {
  const rowsPerPage = table.getState().pagination.pageSize;
  let page: number = table.getState().pagination.pageIndex;

  const rowId = parseInt(row.id) + 1;

  return <label>{page * rowsPerPage + rowId}</label>;
};

export const displayButton = (row: any, field: string, Method: any) => {
  if (row.original[field] === "SUCCESS") {
    return null;
  }
  if (row.original[field] === "FAILURE") {
    return null;
  }
  if (row.original[field] === "APPROVED") {
    return null;
  }
  if (row.original[field] === "REJECTED") {
    return null;
  }

  return (
    <div>
      <Button
        variant="contained"
        onClick={(e) => Method(row.original)}
        style={{ padding: "2px 10px", color: "white" }}
      >
        Action
      </Button>
    </div>
  );
};

export const customStatus = (row: any) => {
  let value = row?.original["Status"]!;
  if (value === SUCCESS || value === APPROVED || value === COMPLETED)
    return successBox(value);
  else if (value === FAILURE || value === REJECTED) return errorBox(value);
  else if (value === PROCESS || value === PENDING) return warningBox(value);
  else return warningBox(PROCESS);
};

export const customPaymentStatus = (row: any) => {
  let value = row?.original["PaymentStatus"]!;
  if (value === SUCCESS || value === APPROVED || value === COMPLETED)
    return successBox(value);
  else if (value === FAILURE || value === REJECTED) return errorBox(value);
  else if (value === PROCESS || value === PENDING) return warningBox(value);
  else return warningBox(value);
};
export const customReturnStatus = (row: any) => {
  let value = row?.original["ReturnStatus"]!;
  if (value === SUCCESS || value === APPROVED || value === COMPLETED)
    return successBox(value);
  else if (value === FAILURE || value === REJECTED) return errorBox(value);
  else if (value === PROCESS || value === PENDING) return warningBox(value);
  else return warningBox(value);
};

export const displayActionOrViewButton = (
  row: any,
  field: string,
  Method: any
) => {
  if (row.original[field] === "PROCESS" || row.original[field] === "PENDING") {
    return (
      <div>
        <Button
          variant="contained"
          onClick={(e) => Method(row.original)}
          style={{ padding: "2px 10px", color: "white" }}
        >
          Action
        </Button>
      </div>
    );
  } else {
    return (
      <div>
        <Button
          variant="contained"
          onClick={(e) => Method(row.original)}
          style={{ padding: "2px 10px", background: "blue", color: "white" }}
        >
          View
        </Button>
      </div>
    );
  }
};

export const editButton = (row: any, Method: any) => {
  return (
    <div>
      <EditIcon color="primary" onClick={(e) => Method(row.original)} />
    </div>
  );
};

export const urlButton = (row: any, Method: any) => {
  return (
    <div>
      <TouchAppIcon color="secondary" onClick={(e) => Method(row.original)} />
    </div>
  );
};

export const deleteButton = (row: any, Method: any) => {
  return (
    <div>
      <DeleteIcon color="error" onClick={(e) => Method(row.original)} />
    </div>
  );
};

export const Setup = (row: any, Method: any) => {
  return (
    <div>
      <SettingsIcon color="primary" onClick={(e) => Method(row.original)} />
    </div>
  );
};

export const activate = (row: any, field: string, updateValue: any) => {
  let value = row.original[field];
  return (
    <FormControlLabel
      value={value}
      control={
        <Switch
          size="small"
          color="primary"
          checked={value}
          onChange={(event) => {
            let newValue = event.target.checked;
            updateValue(row.original, newValue);
          }}
          value={value}
        />
      }
      label={""}
    />
  );
};

export const updateButton = (row: any, Method: any) => {
  return (
    <div>
      <Button
        variant="contained"
        onClick={(e) => Method(row.original)}
        style={{ padding: "2px 10px", backgroundColor: "#ffa500" }}
      >
        Update
      </Button>
    </div>
  );
};

export const TextboxEdit = (field: string, row: any, updateValue: any) => {
  const [value, setValue] = useState(row?.original[field]!);

  return (
    <div>
      <TextField
        id={field + "_" + row?.index}
        variant="outlined"
        style={{ width: "100%", padding: "5px 5px" }}
        value={value}
        onChange={(event) => {
          let newValue = event.target.value;
          updateValue(row?.index, field, newValue);
          setValue(newValue);
        }}
      />
    </div>
  );
};

export const TextboxEditTest = (
  field: string,
  row: any,
  updateValue: any,
  table: any,
  length?: number
) => {
  const [value, setValue] = useState(row?.original[field]!);

  return (
    <div>
      <TextField
        id={field + "_" + row?.index}
        variant="outlined"
        style={{ width: "100%", padding: "5px 5px" }}
        value={value}
        autoComplete="false"
        multiline={true}
        size="small"
        fullWidth
        inputProps={{
          maxLength: length,
          style: {
            fontSize: 14,
            width: "-webkit-fill-available",
          },
        }}
        onChange={(event) => {
          let newValue = event.target.value;
          updateValue(row?.index, field, newValue, table);
          setValue(newValue);
        }}
      />
    </div>
  );
};

export const TextboxKeyEdit = (field: string, row: any, updateValue: any) => {
  const [value, setValue] = useState(row?.original[field]!);

  return (
    <div>
      <TextField
        style={{ width: "100%" }}
        value={value}
        onChange={(event) => {
          let newValue = event.target.value;
          updateValue(row?.index, field, newValue);
          setValue(newValue);
        }}
      />
    </div>
  );
};

export const currencyRupeeButton = (row: any, Method: any) => {
  return (
    <div>
      <CurrencyRupeeIcon
        color="secondary"
        onClick={(e) => Method(row.original)}
      />
    </div>
  );
};

export const ReadOnlyTextArea = (row: any, field: string) => {
  let value = row?.original[field]!;
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <div
          style={{
            whiteSpace: "normal",
            fontSize: "12px",
            wordBreak: "break-word",
            width: "auto",
          }}
        >
          {value}
        </div>
      </Grid>
    </Grid>
  );
};

export const DisplayAsPassword = (field: string, row: any) => {
  let value = row?.original[field]!;

  let len = value.length;
  var str = new Array(len + 1).join("*");

  let IsActive = row?.original["IsActive"]!;

  return IsActive === true ? (
    <span style={{ color: "green", fontWeight: "bold" }}>{str}</span>
  ) : (
    <span style={{ color: "black" }}>{str}</span>
  );
};

export const ActiveText = (field: string, row: any) => {
  let value = row?.original[field]!;
  let IsActive = row?.original["IsActive"]!;

  return IsActive === true ? (
    <span style={{ color: "green", fontWeight: "bold" }}>{value}</span>
  ) : (
    <span style={{ color: "black" }}>{value}</span>
  );
};
export const displayButtonDynamicName = (
  row: any,
  name: string,
  Method: any
) => {
  return (
    <div>
      <Button
        variant="contained"
        onClick={(e) => Method(row.original)}
        style={{ padding: "2px 10px" }}
      >
        {name}
      </Button>
    </div>
  );
};

export const displayButtonVerify = (row: any, name: string, Method: any) => {
  return row.original.IsNameVerified === true ? (
    <Box
      sx={{
        backgroundColor: "#20bd61",
        color: "#000",
        margin: "3px",
        borderRadius: "3px",
      }}
    >
      <Typography
        variant="overline"
        display="block"
        gutterBottom
        style={{ textAlign: "center" }}
      >
        verified
      </Typography>
    </Box>
  ) : (
    // <div>
    //   <Button variant="contained" style={{ padding: '2px 10px' }}>
    //    verified
    //   </Button>
    // </div>
    <div>
      <Button
        variant="contained"
        onClick={(e) => Method(row.original)}
        style={{ padding: "2px 10px", backgroundColor: "red" }}
      >
        {name}
      </Button>
    </div>
  );
};

export const userAssign = (row: any, Method: any) => {
  return (
    <div>
      <AssignmentIndIcon
        color="primary"
        onClick={(e) => Method(row.original)}
      />
    </div>
  );
};

export const ImageView = (Path: string) => {
  const [open, setOpen] = useState(false);
  if (Path === null || Path === undefined || Path === "") {
    return null;
  }

  const handleImage = (Path: string) => {
    // window.open(Path);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        onMouseEnter={() => handleImage(Path)}
        onMouseLeave={handleClose}
        sx={{ position: "relative" }}
      >
        <img
          src={Path}
          className="gridImg"
          alt="img"
          height={30}
          width={30}
          style={{}}
        />
        {open && (
          <img
            src={Path}
            alt={"User"}
            width={300}
            height={200}
            sizes="100vw"
            style={{
              position: "absolute",
              zIndex: "9999999999",
              bottom: "-85px",
              left: "60px",
              borderRadius: "10px",
              background: "#fff",
              border: "2px solid #fff",
              boxShadow: "rgba(0, 0, 0, 0.25) 0px 25px 50px -12px",
            }}
          />
        )}
      </Button>
    </>
  );
};

export const VideoView = ({ videoPath }: { videoPath: string }) => {
  if (!videoPath) return null;
  return (
    <video width="320" height="240" controls>
      <source src={videoPath} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};

export const Download = (row: any, field: string) => {
  return (
    <Button
      style={{ marginLeft: "5%", marginTop: "5%" }}
      onClick={() => {
        //  window.open(row?.original[field]!);
      }}
    >
      <DownloadIcon />
    </Button>
  );
};

export const MoreAction = ({ Menues }: any) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "20ch",
          },
        }}
      >
        {Menues.map((option: any) => (
          <MenuItem
            key={option.label}
            onClick={() => {
              handleClose();
              option.action();
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export const CustomCommission = (
  field: string,
  row: any,
  updateValue: any,
  table: any
) => {
  const [value, setValue] = useState(row?.original[field]!);
  const { Commission, CommissionType, CommissionCategory } =
    value !== undefined
      ? value
      : {
          Commission: 0,
          CommissionType: "Fix",
          CommissionCategory: "Commission",
        };

  const [Fix, setFix] = useState(CommissionType);

  const [CommissionValue, setCommission] = useState(Commission);

  const [Surcharge, setSurcharge] = useState(CommissionCategory);

  const handleFixClick = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string
  ) => {
    setFix(newValue);
    updateValue(row?.index, field + ".CommissionType", newValue, table);
  };

  const handleSurchargeClick = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string
  ) => {
    setSurcharge(newValue);
    updateValue(row?.index, field + ".CommissionCategory", newValue, table);
  };

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    var newValue = event.target.value;
    setCommission(newValue);
    updateValue(row?.index, field + ".Commission", newValue, table);
  };

  const get_Commission = (Commission: any) => {
    if (Commission === undefined) return 0;

    if (Commission === null) return 0;

    if (Commission.length <= 0) return 0;

    // if (typeof Commission === 'number') {
    //   if (Commission.count_decimals() <= 2) {
    //     return Commission.toFixed(2);
    //   }
    // }

    return Commission;
  };
  return (
    <div>
      <TextField
        variant="outlined"
        margin="normal"
        color="primary"
        className={styles.font_size}
        InputProps={{
          className: styles.textField,
        }}
        value={get_Commission(CommissionValue)}
        inputProps={{
          maxLength: 5,
          inputMode: "numeric",
          pattern: "[0-9]*",
        }}
        onChange={handleValueChange}
      />
      <br />
      <ToggleButtonGroup
        exclusive
        size="small"
        color="primary"
        value={Surcharge}
        onChange={handleSurchargeClick}
        style={{ fontSize: "12px", height: "18px" }}
      >
        <ToggleButton value={"Commission"} style={{ fontSize: "12px" }}>
          COM
        </ToggleButton>
        <ToggleButton value={"Surcharge"} style={{ fontSize: "12px" }}>
          SUR
        </ToggleButton>
      </ToggleButtonGroup>
      <ToggleButtonGroup
        exclusive
        size="small"
        color="primary"
        value={Fix}
        onChange={handleFixClick}
        style={{ fontSize: "12px", height: "18px" }}
      >
        <ToggleButton value={"Flexi"} style={{ fontSize: "12px" }}>
          PER
        </ToggleButton>
        <ToggleButton value={"Fix"} style={{ fontSize: "12px" }}>
          FIX
        </ToggleButton>
      </ToggleButtonGroup>
      <br />
    </div>
  );
};

export const TextboxNumberAmount = (
  field: string,
  row: any,
  updateValue: any,
  table: any,
  length?: number
) => {
  const [value, setValue] = useState(row?.original[field]!);

  return (
    <div>
      <TextField
        id={field + "_" + row?.index}
        variant="outlined"
        style={{ width: "80%", padding: "5px 5px" }}
        label="Amount"
        value={value}
        margin="dense"
        autoComplete="false"
        multiline={true}
        fullWidth
        inputProps={{
          maxLength: 7,
          style: {
            fontSize: 14,
            width: "-webkit-fill-available",
          },
          inputComponent: NumberFormatCustom,
        }}
        onChange={(event) => {
          let newValue = event.target.value;
          updateValue(row?.index, field, newValue, table);
          setValue(newValue);
        }}
      />
    </div>
  );
};

const NumberFormatCustom = (props: any) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <></>

    // <NumberFormat
    //   {...other}
    //   getInputRef={inputRef}
    //   onValueChange={(values:any) => {
    //     onChange({
    //       target: {
    //         name: props.name,
    //         value: values.value,
    //       },
    //     });
    //   }}
    // />
  );
};

export const CustomSuccessFail = (value: string, callback: any) => {
  const SuccessFailCallback = (ReferenceNo: string, Status: string) => {
    callback(value, ReferenceNo, Status);
  };
  return (
    <Grid item xs={12} md={12} lg={12} sm={12}>
      <SuccessFailComponent
        value={value}
        callback={(ReferenceNo: string, Status: string) => {
          SuccessFailCallback(ReferenceNo, Status);
        }}
      />
    </Grid>
  );
};
const SuccessFailComponent = ({ value, callback }: any) => {
  const [TransactionID, setTransactionID] = useState("");
  return (
    <>
      <Grid item xs={12} md={12} lg={12} sm={12}>
        <TextField
          variant="outlined"
          margin="normal"
          placeholder="TransactionID"
          color="primary"
          style={{ fontSize: "12px" }}
          InputProps={{
            style: {
              height: "25px",
            },
          }}
          value={TransactionID}
          onChange={(event) => {
            let newValue = event.target.value;
            setTransactionID(newValue);
          }}
        />
      </Grid>

      <Grid container>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <IconButton
            aria-label="REFUND"
            title="REFUND"
            style={{
              color: "red",
              marginTop: "-10px",
            }}
            onClick={() => {
              callback(TransactionID, "REFUND");
            }}
          >
            <DeleteForeverIcon style={{ color: "red" }} />
          </IconButton>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <IconButton
            aria-label="SUCCESS"
            title="SUCCESS"
            style={{
              color: "green",
              marginTop: "-10px",
            }}
            onClick={() => {
              callback(TransactionID, "SUCCESS");
            }}
          >
            <CheckCircleIcon style={{ color: "green" }} />
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
};

interface Props {
  children: React.ReactNode;
}
export const Zoomable: React.FC<Props> = ({ children }) => {
  const [scale, setScale] = useState(1);

  const handleWheel = (delta: number) => {
    setScale((prevScale) => prevScale + delta * 0.01);
  };

  useEffect(() => {
    const handleWheelEvent = (event: WheelEvent) => {
      event.preventDefault();
      handleWheel(event.deltaY);
    };
    window.addEventListener("wheel", handleWheelEvent);
    return () => window.removeEventListener("wheel", handleWheelEvent);
  }, []);

  return <div style={{ transform: `scale(${scale})` }}>{children}</div>;
};
