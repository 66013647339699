export const SERVER_DOWN_ERROR =
  "Error : Server down! Please try again after some time!";

export const UNABLE_TO_PROCESS_REQUEST_ERROR =
  "Unable to process request. Please try after some time!";

export const UNAUTHORIZED_ACCESS_REQUEST_ERROR =
  "Unauthorized access to this request or page!";

export const MESSAGE_CLOSE_AFTER = 2 * 1000;
