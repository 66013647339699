import { useTranslation } from "react-i18next";
import UseViewScheme from "src/pages/outerPages/ViewSchema/useViewScheme";
import CustomSchemesCard from "src/component/uiElements/card/schemesCard";
import { Box, Typography, Grid, Container } from "@mui/material";
import { useLocation } from "react-router-dom";

const ViewSchemas = () => {
    const location = useLocation();
    const schemeMasterID = location.state;

    const { t } = useTranslation("scheme");
    const { schemeList } = UseViewScheme(schemeMasterID);

    return (
        <>
            <Box className="innercontent">
                <Box className="pagehead">
                    <Typography className="innerpagehead">Scheme</Typography>
                </Box>
                <Container fixed sx={{ marginBottom: "70px" }}>
                    <Grid container spacing={3} sx={{ marginTop: "30px" }}>
                        {schemeList.map((scheme, index) => (
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} key={index}>
                                <CustomSchemesCard
                                    title={scheme.SchemeName}
                                    description={scheme.Description}
                                    buttonLink={`/${scheme.SchemeMasterID}`}
                                    linkTo={`/${scheme.SchemeMasterID}`}
                                />
                            </Grid>
                        ))}
                    </Grid>

                </Container>
            </Box>
        </>
    );
}

export default ViewSchemas;
