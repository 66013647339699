import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box, Link } from "@mui/material";

interface CustomCategoriesCardProps {
  title: string;
  imageUrl: string;
  ButtonName: string;
  categoryMasterID: number;
  onClick?: (categoryMasterID: number) => void;
}

const CustomCategoriesCard: React.FC<CustomCategoriesCardProps> = ({
  title,
  imageUrl,
  ButtonName,
  categoryMasterID,
  onClick,
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick(categoryMasterID);
    }
  };
  return (
    <Box className="allCategory">
      <CardMedia
        component="img"
        className="categoryimage"
        image={imageUrl}
        alt={title}
      />
      <Typography>{title}</Typography>
      <Link className="findschemes" onClick={handleClick}>
        {ButtonName}
      </Link>
    </Box>
  );
};

export default CustomCategoriesCard;
