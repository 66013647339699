import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Button, Grid, Link } from "@mui/material";

interface CustomSchemesCardProps {
  title: string;
  description: string;
  buttonLink: string;
  linkTo: string;
}

const CustomSchemesCard: React.FC<CustomSchemesCardProps> = ({
  title,
  description,
  buttonLink,
  linkTo,
}) => {
  return (
    <Card
      sx={{
        boxShadow: "0 0 0 0",
        borderRadius: "10px",
      }}
    >
      <CardContent sx={{ px: "24px" }}>
        <Link href={linkTo} sx={{ textDecoration: "none" }}>
          <Typography
            sx={{
              color: "#222",
              fontSize: "26px",
              fontWeight: "600",
              textTransform: "capitalize",
              "&:hover": {
                color: "#95989a",
              },
            }}
          >
            {title}
          </Typography>
        </Link>

        <Typography
          sx={{
            mt: "10px",
            fontWeight: "400",
            fontSize: "16px",
            color: "#40434f",
            lineHeight: "24px",
            "@media (max-width: 1311px)": {
              textAlign: "justify",
            },
          }}
        >
          {description}
        </Typography>

        <Grid sx={{ mt: "12px" }}>
          <Button
            href={buttonLink}
            sx={{
              color: "#81859f",
              fontSize: "12px",
              textTransform: "none",
              bgcolor: "rgb(241, 241, 241)",
              borderRadius: "20px",
              height: "28px",
              px: "12px",
              "&:hover": {
                color: "#fff",
                bgcolor: "#1a202c",
              },
            }}
          >
            Show document
          </Button>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CustomSchemesCard;
