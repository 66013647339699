import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import AuthInnerNavbar from "src/component/navbar/authInnerNavbar";
import { LOGOUT } from "../helper/constants";
import { useDispatch } from "react-redux";
import { logout as logoutAction } from "src/store/authSlice";

const Dashborad = lazy(
  () => import("src/pages/innerPages/dashborad/dashborad")
);
const UserRegistration = lazy(
  () => import("src/pages/innerPages/add_user/addUserScreen")
);
const AllUserReportScreen = lazy(
  () => import("src/pages/innerPages/report/all_user/allUserReportScreen")
);
const AddCategoryScreen = lazy(
  () => import("src/pages/innerPages/add_category/addCategoryScreen")
);
const AddServicesScreen = lazy(
  () => import("src/pages/innerPages/add_services/addServicesScreen")
);
const AddSchemeScreen = lazy(
  () => import("src/pages/innerPages/add_scheme/addSchemeScreen")
);

const ApplicantRegisterScreen = lazy(
  () =>
    import("src/pages/innerPages/applicant_register/applicantRegisterScreen")
);
const KeySettingScreen = lazy(
  () => import("src/pages/innerPages/key_setting/keySettingScreen")
);

const MessageSettingScreen = lazy(
  () => import("src/pages/innerPages/message_setting/messageSettingScreen")
);

const UsersProfileScreen = lazy(
  () => import("src/pages/innerPages/profile/profileScreen")
);
const ChangePasswordAddScreen = lazy(
  () => import("src/pages/innerPages/change_password/changePasswordAddScreen")
);

const SMSSettingAddScreen = lazy(
  () => import("src/pages/innerPages/sms/smsSettingAddScreen")
);

const AddTestimonialScreen = lazy(
  () => import("src/pages/innerPages/add_testimonials/addTestimonialScreen")
);

const AllApplicantReportScreen = lazy(
  () =>
    import(
      "src/pages/innerPages/report/applicant_report/allApplicantReportScreen"
    )
);

const AllSchemeReportScreen = lazy(
  () =>
    import("src/pages/innerPages/report/scheme_report/allSchemeReportScreen")
);

const UserEditScreen = lazy(
  () => import("src/pages/innerPages/report/all_user/editScreen")
);

const GramSathiQuestionAddScreen = lazy(
  () =>
    import("src/pages/innerPages/gramSathiQuestion/GramSathiquestionAddScreen")
);

const GramSathiAnswerAddScreen = lazy(
  () => import("src/pages/innerPages/gramSathiQue&Ans/gramSathiAnswerAddScreen")
);

const SmsReportScreen = lazy(
  () => import("src/pages/innerPages/sent_sms_report/smsReportScreen")
);
const AnswerReportScreen = lazy(
  () =>
    import("src/pages/innerPages/report/answer_report/allAnswerReportScreen")
);
const AllRedirectUserReportScreen = lazy(
  () =>
    import(
      "src/pages/innerPages/report/rediect_user_report/allRedirectUserReportScreen"
    )
);

const CustomerDashboard = lazy(
  () =>
    import(
      "src/pages/innerPages/dashborad/customer_dashboard/customerDashboardScreen"
    )
);

const GramsathiDashboard = lazy(
  () =>
    import(
      "src/pages/innerPages/dashborad/gramsathi_dashboard/gramSathiDashboardScreen"
    )
);

const SupportDashboard = lazy(
  () =>
    import(
      "src/pages/innerPages/dashborad/support_dashboard/supportDashboardScreen"
    )
);
const AIBassDataUploadScreen = lazy(
  () => import("src/pages/innerPages/AI-FileUpload/aIFileUploadScreen")
);

const MainRoute: React.FC = () => {
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logoutAction());
    LOGOUT();
  };

  return (
    <AuthInnerNavbar>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/UserRegistration" element={<UserRegistration />} />
          <Route path="/Dashboard" element={<Dashborad />} />
          <Route
            path="/Logout"
            element={<LogoutComponent onLogout={handleLogout} />}
          />
          <Route path="/UserReport" element={<AllUserReportScreen />} />
          <Route path="/AddCategory" element={<AddCategoryScreen />} />
          <Route path="/AddServices" element={<AddServicesScreen />} />
          <Route path="/AddScheme" element={<AddSchemeScreen />} />
          <Route path="/ChangePassword" element={<ChangePasswordAddScreen />} />
          <Route path="/Profile" element={<UsersProfileScreen />} />
          <Route path="/AddApplicant" element={<ApplicantRegisterScreen />} />
          <Route path="/KeySetting" element={<KeySettingScreen />} />
          <Route path="/MessageSetting" element={<MessageSettingScreen />} />
          <Route
            path="/ApplicantReport"
            element={<AllApplicantReportScreen />}
          />
          <Route path="/SmsSetting" element={<SMSSettingAddScreen />} />
          <Route path="/AddTestimonial" element={<AddTestimonialScreen />} />
          <Route path="/EditScreen" element={<UserEditScreen />} />
          <Route path="/AddQuestion" element={<GramSathiQuestionAddScreen />} />
          <Route path="/AddAnswer" element={<GramSathiAnswerAddScreen />} />
          <Route path="/AddScheme" element={<AddSchemeScreen />} />
          <Route path="/EditScreen" element={<UserEditScreen />} />
          <Route path="/AddScheme" element={<AddSchemeScreen />} />
          <Route path="/EditScreen" element={<UserEditScreen />} />
          <Route path="/Sms_Report" element={<SmsReportScreen />} />
          <Route path="/AnswerReport" element={<AnswerReportScreen />} />
          <Route path="/SchemeReport" element={<AllSchemeReportScreen />} />
          <Route path="/EditScreen" element={<UserEditScreen />} />
          <Route path="/Sms_Report" element={<SmsReportScreen />} />
          <Route
            path="/RedirectUserReport"
            element={<AllRedirectUserReportScreen />}
          />
          <Route path="/CustomerDashboard" element={<CustomerDashboard />} />
          <Route path="/GramSathiDashboard" element={<GramsathiDashboard />} />
          <Route path="/SupportDashboard" element={<SupportDashboard />} />
          <Route path="/AIFileUpload" element={<AIBassDataUploadScreen />} />
        </Routes>
      </Suspense>
    </AuthInnerNavbar>
  );
};

export default MainRoute;

interface LogoutComponentProps {
  onLogout: () => void;
}

const LogoutComponent: React.FC<LogoutComponentProps> = ({ onLogout }) => {
  React.useEffect(() => {
    onLogout();
  }, [onLogout]);

  return <div>Logging out...</div>;
};
