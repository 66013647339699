export const resources = {
  en: {
    profile: {
      Profile: "Profile",
      EmailID: "Email ID",
      "User Name": "User Name",
      "Mobile Number": "Mobile Number",
      "Profile Image": "Profile Image",
      "User Type": "User Type",
      "WhatsApp MobileNumber": "WhatsApp MobileNumber",
      Address: "Address",
      "Update Now": "Update Now",
      "Profile upload": "Profile upload",
      "Click here to upload image/Document":
        "Click here to upload image/Document",
    },
    changePassword: {
      "Change Password": "Change Password",
      "Old Password": "Old Password",
      "New Password": "New Password",
      "Confirm Password": "Confirm Password",
      "Please provide valid new password": "Please provide valid new password",
      "Please provide valid old password": "Please provide valid old password",
      "Please provide valid confirm Password":
        "Please provide valid confirm Password",
      Update: "Update",
      Reset: "Reset",
    },
    addUserScreen: {
      "User Register": "User Register",
      "With Form": "With Form",
      "With Aadhar Number": "With Aadhar Number",
      "User Name": "User Name",
      "Mobile number": "Mobile number",
      "WhatsApp number(Optional)": "WhatsApp number(Optional)",
      UserType: "UserType",
      AreaName: "AreaName",
      "Email ID (Optional)": "Email ID (Optional)",
      Cancel: "Cancel",
      Submit: "Submit",
      "Applicant Adhar Number": "Applicant Adhar Number",
      "Generate OTP": "Generate OTP",
      "Adhar Number": "Adhar Number",
      "Mobile number is required": "Mobile number is required",
      "User name is required": "User name is required",
      "Select Usertype": "Select Usertype",
      "Select Area": "Select Area",
    },
    CustomMultipleImagePicker: {
      "Click here to upload image": "Click here to upload image",
    },
    CustomImagePicker: {
      "Click here to upload image/Document":
        "Click here to upload image/Document",
    },
    CustomVideoPicker: {
      "Click here to upload Video": "Click here to upload Video",
    },
    public: {
      Home: "Home",
      About: "About",
      Schemes: "Schemes",
      Categories: "Categories",
      "Sign in": "Sign in",
    },
    redirectUserReportScreen: {
      "Redirect User Report": "Redirect User Report",
      "Selection Criteria": "Selection Criteria",
      UserName: "User Name",
      Link: "Link",
      Search: "Search",
      FromDate: "From Date",
      ToDate: "To Date",
      Date: "Date",
      Count: "Count",
      Submit: "Submit",
      "Select user": "Select user",
    },
    userReportScreen: {
      "All Users": "All Users",
      "Selection Criteria": "Selection Criteria",
      MobileNumber: " Mobile Number",
      WhatsAppNumber: "WhatsApp Number",
      EmailID: "Email ID",
      UserType: "User Type",
      UserName: "User Name",
      Address: "Address",
      Date: "Date",
      All: "All",
      CUSTOMER: "CUSTOMER",
      SUPPORT: "SUPPORT",
      GRAMSATHI: "GRAMSATHI",
      "Join Date": "Join Date",
    },
    smsReportScreen: {
      "Sent SMS Report": "Sent SMS Report",
      "Select user": "Select user",
      FromDate: "From Date",
      ToDate: "To Date",
      UserName: "User Name",
      Date: "Date",
      MobileNumber: "Mobile Number",
      Message: "Message",
      Type: "Type",
      MessageMedium: "Message Medium",
      Submit: "Submit",
    },
    keySettingScreen: {
      "Key Setting": "Key Setting",
      Key: "Key",
      Value: "Value",
      Secure: "Secure",
      Update: "Update",
    },
    addCategoryScreen: {
      "Add Category": "Add Category",
      Category: "Category",
      CategoryName: "Category Name",
      Edit: "Edit",
      Delete: "Delete",
      Cancel: "Cancel",
      Add: "Add",
      Update: "Update",
      "Category is required": "Category is required",
    },
    addServicesScreen: {
      "Add Services": "Add Services",
      "Select Image": "Select Image",
      ServicesDetails: "Service Details",
      Document: "Document",
      Category: "Category",
      Service: "Service(ServicesDetails)",
      Link: "Link",
      Edit: "Edit",
      Delete: "Delete",
      ServicesLink: "Services Link",
      SelectImage: "Select Image",
      CategoryName: "CategoryName",
      Cancel: "Cancel",
      Add: "Add",
      Update: "Update",
      "Service details is required": "Service details is required",
      "Select category": "Select category",
    },
    addTestimonialScreen: {
      "Add Testimonial": "Add Testimonial",
      "Select Image": "Select Image",
      Document: "Document",
      Name: " Name",
      Profession: " Profession",
      Description: "Description",
      Video: "Video",
      Edit: "Edit",
      Delete: "Delete",
      AddName: " Name",
      AddProfession: "Add Profession",
      AddDescription: "Add Description",
      Cancel: "Cancel",
      Add: "Add",
      Update: "Update",
      "Testimonial name is required": "Testimonial name is required",
      "Click here to upload Image": "Click here to upload Image",
      "Select Video": "Select Video",
      "Click here to upload Video": "Click here to upload Video",
    },
    applicantReportScreen: {
      "Applicant Report": "Applicant Report",
      "Selection Criteria": "Selection Criteria",
      Document: "Document",
      Honorifics: "Honorifics",
      ApplicantName: "Applicant Name",
      MobileNumber: "Mobile Number",
      Pincode: "Pincode",
      Address: "Address",
      Religion: "Religion",
    },
    applicantPersonalRegistrationScreen: {
      "Applicant Personal Details": "Applicant Personal Details",
      "Select user": "Select user",
      "Select state": "Select state",
      "Select district": "Select district",
      "Select taluka": "Select taluka",
      "Select Gender": "Select Gender",
      "Select honorifics": "Select honorifics",
      UserName: "UserName",
      Honorifics: "Honorifics",
      FirstName: "First Name",
      MiddleName: "Middle Name",
      LastName: "LastName",
      GenderName: "Gender Name",
      Address: "Address",
      Village: "Village",
      StateName: "State Name",
      DistrictName: "District Name",
      TalukaName: "Taluka Name",
      PinCode: "Pin Code",
      DOB: "Date of Birth",
      PlaceOfBirth: "Place Of Birth",
      Age: "Age",
      MotherTongue: "Mother Tongue",
      FatherName: "Father's Name",
      MotherName: "Mother's Name",
      Cancel: "Cancel",
      Add: "Add",
      Update: "Update",
      "PinCode Number is required": "PinCode Number is required",
    },
    schemeReportScreen: {
      "Scheme Report": "Scheme Report",
      "Scheme Name": "Scheme Name",
      "Scheme Type": "Scheme Type",
      Description: "Description",
      Link: "Link",
      "Required Documents": "Required Documents",
      Document: "Document",
      "Eligibility Criteria": "Eligibility Criteria",
    },
    applicantParentRegistrationScreen: {
      "Applicant Parent Details": "Applicant Parent Details",
      "Select user": "Select user",
      UserName: "UserName",
      Address: "Address",
      Village: "Village",
      StateName: "State Name",
      DistrictName: "District Name",
      TalukaName: "Taluka Name",
      PinCode: "Pin Code",
      SelectMaritalStatus: "Select Marital Status",
      SpouseName: "Spouse Name",
      EmailID: "Email ID",
      ContactNumber: "Contact Number",
      AdharNumber: "Adhar Number",
      PanNumber: "Pan Number",
      VoterIDCardNumber: "VoterIDCard Number",
      Other: "Other",
      MaritalStatus: "Marital Status ",
      Cancel: "Cancel",
      Add: "Add",
      Update: "Update",
      "Select state": "Select state",
      "Select district": "Select district",
      "Select taluka": "Select taluka",
      "Adhar number is required": "Adhar number is required",
    },
    applicantFamilyRegistrationScreen: {
      "Applicant Family Details": "Applicant Family Details",
      "Select user": "Select user",
      UserName: "User Name",
      NameOfHeadOfFamily: "Name Of Head Of Family",
      ReligionName: "Religion Name",
      ReligionOther: "Religion Other",
      EarningSource: "Earning Source",
      Range: "Range",
      FamilyMembers: "Family Members",
      SpentAmount: "Spent Amount",
      RationCardNumber: "RationCard Number",
      Cancel: "Cancel",
      Add: "Add",
      Update: "Update",
      "Ration Card Number is required": "Ration Card Number is required",
      "Select Income Range": "Select Income Range",
      "Select Religion": "Select Religion",
    },
    applicantDocumentScreen: {
      "Applicant Document": "Applicant Document",
      "Select user": "Select user",
      "Select Image": "Select Image",
      UserName: "User Name",
      "Applicant Photo": "Applicant Photo",
      "AdharCard Front": "Adhar Card Front",
      "AdharCard Back": "Adhar Card Back",
      "Pan Card": "Pan Card",
      "VoterID Card": "Voter ID Card",
      "Ration Card": "Ration Card",
      Other: "Other",
      Cancel: "Cancel",
      Submit: "Submit",
    },
    applicantRegistrationScreen: {
      "Applicant Registration": "Applicant Registration",
      "APPLICANT PERSONAL DETAILS": "APPLICANT PERSONAL DETAILS",
      "APPLICANT PARENT DETAILS": "APPLICANT PARENT DETAILS",
      "APPLICANT FAMILY DETAILS": "APPLICANT FAMILY DETAILS",
      "APPLICANT DOCUMENT": "APPLICANT DOCUMENT",
    },
    addSchemeDetailsScreen: {
      "Add Scheme": "Add Scheme",
      "Select category": "Select category",
      "Select service": "Select service",
      "Select State": "Select State",
      "Select Religion": "Select Religion",
      "Select Gender": "Select Gender",
      "Select Income Range": "Select Income Range",
      SchemeName: "Scheme Name",
      Description: "Description",
      ServicesDetails: "Services Details",
      Link: "Link",
      CategoryName: "Category Name",
      SchemeType: "Scheme Type",
      FromAge: "FromAge",
      ToAge: "ToAge",
      Education: "Education",
      ReligionName: "Religion Name",
      GenderName: "Gender Name",
      Range: "Range",
      SubReligion: "SubReligion",
      AreaZone: "AreaZone",
      StateName: "State Name",
      OrphanedChildren: "Orphaned Children",
      Crippled: "Crippled",
      MinorLandHolders: "Minor Land Holders",
      LandLessShetMajur: "Land Less Shet Majur",
      DestituteMen: "Destitute Men",
      DestituteWomen: "Destitute Women",
      NaturalDeath: "Natural Death",
      SubDuction: "SubDuction",
      HortiCulture: "HortiCulture",
      ArableFarmLand: "Arable Farm Land",
      Beneficiary: "Beneficiary",
      DivorcedButNotReceivingAlinony: "Divorced But Not Receiving Alinony",
      AllCategoryOfDisabledDisease: "AllCategory Of Disabled Disease",
      TheWidow: "The Widow",
      BankPassbook: "Bank Passbook",
      AdharCard: "Adhar Card",
      RationCard: "Ration Card",
      RahivasiDhakhla: "Rahivasi Dhakhla",
      PovertyLineCertificate: "Poverty Line Certificate",
      SaatBaaraUtara: "SaatBaara Utara",
      landrecorddocument: "land record document",
      FamilyPlaningCertificate: "Family Planing Certificate",
      Benefit: "Benefit",
      Department: "Department",
      DivorcedStatus: "Divorced Status",
      TypesofDiseases: "Types of Diseases",
      AgriculturalLand: "Agricultural Land",
      PovertyCertificate: "Poverty Certificate",
      MinorLandowners: "Minor Landowners",
      "8A": "8A",
      "Select Image": "Select Image",
      "Additional Information": "Additional Information",
      "Additional Information 1": "Additional Information 1",
      "Additional Information 2": "Additional Information 2",
      "Additional Information 3": "Additional Information 3",
      Cancel: "Cancel",
      Add: "Add",
      Update: "Update",
      "Select tag": "Select tag",
      OnlineOffline: "Online Offline",
    },
    dashboardScreen: {
      Dashboard: "Dashboard",
      TotalSchemes: "Total Schemes",
      AllUsers: "All Users",
      UserConnects: "User Connects",
      TotalBenefits: "Total Benefits",
      TopTrendingCategories: "Top Trending Categories",
      ThisWeekTrendingCategories: "This Week Trending Categories",
      TotalUserReport: "Total User Report",
    },
    mainMenuList: {
      Scheme: "Scheme",
      "User Registration": "User Registration",
      "Applicant Registration": "Applicant Registration",
      "Question Bank": "Question Bank",
      Dashboard: "Dashboard",
      Report: "Report",
      Logout: "Logout",
      Master: "Master",
      Setting: "Setting",
      "AI File Upload": "AI File Upload",
    },
    subMenuList: {
      "Add Scheme": "Add Scheme",
      "Scheme Report": "Scheme Report",
      "Add Applicant": "Add Applicant",
      "Applicant Report": "Applicant Report",
      "User Report": "User Report",
      "Sms Report": "Sms Report",
      "Key Setting": "Key Setting",
      "Add Category": "Add Category",
      "Add Services": "Add Services",
      "Add Testimonial": "Add Testimonial",
      "Add Question": "Add Question",
      "Add Answer": "Add Answer",
      "Message Setting": "Message Setting",
      "SMS Setting": "SMS Setting",
      "Redirect User Report": "Redirect User Report",
      "Added Answer Report": "Added Answer Report",
    },
    accountMenu: {
      Profile: "Profile",
      "Change Password": "Change Password",
      Logout: "Logout",
    },
    loginScreen: {
      "Welcome back!": "Welcome back!",
      "Please enter your details": "Please enter your details",
      MobileNumber: "Mobile Number",
      Password: "Password",
      "Forgot password?": "Forgot password?",
      "Sign In": "Sign In",
      "Enter phone number": "Enter phone number",
      "Enter password": "Enter password",
      "Don't have an account?": "Don't have an account?",
      "Sign Up": "Sign Up",
      "Phone number is required": "Phone number is required",
      "Password is required": "Password is required",
    },
    forgotPassword: {
      "Forgot Password?": "Forgot Password?",
      "Do you want to login?": "Do you want to login?",
      "Return to Login": "Return to Login",
      "No worries, we'll send you rest instructions.":
        "No worries, we'll send you rest instructions.",
      MobileNumber: "Mobile Number",
      PROCEED: "PROCEED",
      "Mobile Number is required": "Mobile Number is required",
    },
    signUpScreen: {
      "Create an account": "Create an account",
      "Please enter your details": "Please enter your details",
      "User Name": "User Name",
      "Mobile Number": " Mobile Number",
      "WhatsApp Number(Optional)": "WhatsApp number(Optional)",
      "Email ID(Optional)": "Email ID(Optional)",
      Address: "Address",
      Password: "Password",
      "Already have an account?": "Already have an account?",
      "Sign in": "Sign in",
      Reset: "Reset",
      Add: "Add",
      "UserName is required": "UserName is required",
      "Mobile number is required": "Mobile number is required",
    },
    category: {
      Categories: "Categories",
      "Agriculture & Environment": "Agriculture & Environment",
      "Banking & Financial Services": "Banking & Financial Services",
      "Business & Entrepreneurship": "Business & Entrepreneurship",
      "Education & Learning": "Education & Learning",
      "Health & Wellness": "Health & Wellness",
      "Housing & Shelter": "Housing & Shelter",
      Technology: "Technology",
      "Skills & Employment": "Skills & Employment",
      "Social Welfare": "Social Welfare",
      "Women & Child": "Women & Child",
    },
    Schemes: {
      Schemes: "Schemes",
    },
    CustomerDashboardScreen: {
      Dashboard: "Dashboard",
      SchemeBenifit: "Scheme Benifit",
      ApplicantStatus: "Applicant Status",
      "Applicant Personal Detail Status": "Applicant Personal Detail Status",
      "Applicant Parent Detail Status": "Applicant Parent Detail Status",
      "Applicant Family Detail Status": "Applicant Family Detail Status",
    },
    AddQuestion: {
      "Add Question": "Add Question",
      Delete: "Delete",
      Edit: "Edit ",
      Question: "Question",
      Cancel: "Cancel",
      Add: "Add",
      Update: "Update",
      "Select tag": "Select tag",
    },
    AddAnswer: {
      "Add User Details": "Add User Details",
      "Select User": "Select User",
      Remark: "Remark",
      Yes: "Yes",
      No: "No",
      Submit: "Submit",
      Update: "Update",
      Previous: "Previous",
      Next: "Next",
    },
    AddedAnswerReport: {
      "Added Answer Report": "Added Answer Report",
      FromDate: "From Date",
      ToDate: "To Date",
      "Select user": "Select user",
      UserName: "UserName",
      Answer: "Answer",
      Question: "Question",
      Remark: "Remark",
      Submit: "Submit",
    },
    indexscreen: {
      "Select Honorifics": "Select Honorifics",
    },
    AIFileUploadScreen: {
      "AI File Upload": "AI File Upload",
      "Upload File": "Upload File",
      "Choose Files": "Choose Files",
      Reset: "Reset",
    },
  },

  mr: {
    profile: {
      Profile: "प्रोफाइल",
      EmailID: "ईमेल आयडी",
      "User Name": "वापरकर्ता नाव",
      "Mobile Number": "मोबाईल नंबर",
      "WhatsApp MobileNumber": "व्हाट्सअँप मोबाईल नंबर",
      Address: "पत्ता",
      "Profile Image": "प्रोफाइल प्रतिमा",
      "User Type": "वापरकर्ता प्रकार",
      "Update Now": "आता अपडेट करा",
      "Profile upload": "प्रोफाइल अपलोड",
      "Click here to upload image/Document":
        "प्रतिमा/दस्तऐवज अपलोड करण्यासाठी येथे क्लिक करा",
    },
    changePassword: {
      "Change Password": "पासवर्ड बदला",
      "Old Password": "जुना पासवर्ड",
      "New Password": "नवीन पासवर्ड",
      "Confirm Password": "पासवर्डची पुष्टी करा",
      "Please provide valid new password": "कृपया वैध नवीन पासवर्ड द्या",
      "Please provide valid old password": "कृपया वैध जुना पासवर्ड द्या",
      "Please provide valid confirm Password":
        "कृपया वैध पुष्टी संकेतशब्द प्रदान करा",
      Update: "अपडेट",
      Reset: "रीसेट करा",
    },
    addUserScreen: {
      "User Register": "वापरकर्ता नोंदणी",
      "With Form": "फॉर्म सह",
      "With Aadhar Number": "आधार क्रमांकासह",
      "User Name": "वापरकर्त्याचे नाव",
      "Mobile number": "मोबाइल नंबर",
      "WhatsApp number(Optional)": "व्हाट्सअँप नंबर(पर्यायी)",
      UserType: "वापरकर्त्याचा प्रकार",
      AreaName: " क्षेत्राचे नाव:",
      "Email ID (Optional)": "ईमेल आयडी (पर्यायी)",
      Cancel: "रद्द करा",
      Submit: "सबमिट करा",
      "Applicant Adhar Number": " अर्जदार आधार क्रमांक",
      "Adhar Number": "आधार क्रमांक",
      "Generate OTP": "ओटीपी तयार करा ",
      "Mobile number is required": "मोबाइल नंबर आवश्यक आहे",
      "User name is required": "वापरकर्त्याचे नाव आवश्यक आहे",
      "Select Usertype": "वापरकर्ता प्रकार निवडा",
      "Select Area": "क्षेत्र निवडा",
    },
    CustomImagePicker: {
      "Click here to upload image/Document":
        "प्रतिमा/दस्तऐवज अपलोड करण्यासाठी येथे क्लिक करा",
    },
    CustomMultipleImagePicker: {
      "Click here to upload image": "प्रतिमा अपलोड करण्यासाठी येथे क्लिक करा",
    },
    CustomVideoPicker: {
      "Click here to upload Video": "व्हिडिओ अपलोड करण्यासाठी येथे क्लिक करा",
    },
    public: {
      Home: "मुखपृष्ठ",
      About: "प्रकल्पाबद्दल",
      Schemes: "योजना",
      Categories: "श्रेणी",
      "Sign in": "साइन इन",
    },
    redirectUserReportScreen: {
      "Redirect User Report": "पुनर्निर्देशित वापरकर्ते अहवाल",
      "Selection Criteria": "निवड निकष",
      UserName: "वापरकर्ता नाव",
      Link: "लिंक ",
      Search: "शोधा",
      FromDate: "या तारखेपासून",
      ToDate: "तारखेपर्यंत",
      Date: "तारीख",
      Count: "कॉउंट ",
      Submit: "सबमिट करा",
      "Select user": "वापरकर्ता निवडा",
    },
    userReportScreen: {
      "All Users": "सर्व वापरकर्ते",
      All: "सर्व वापरकर्ता",
      CUSTOMER: "ग्राहक",
      SUPPORT: "सपोर्ट ",
      GRAMSATHI: "ग्रामसाथी",
      "Selection Criteria": "निवड निकष",
      UserName: "वापरकर्ता नाव",
      MobileNumber: "मोबाईल नंबर",
      WhatsAppNumber: "व्हॉट्सॲप नंबर",
      UserType: "वापरकर्ता प्रकार",
      EmailID: "ईमेल आयडी",
      Address: "पत्ता",
      Date: "तारीख",
      "Join Date": "रुजू दिनांक",
    },
    smsReportScreen: {
      "Sent SMS Report": "एसएमएस रिपोर्ट",
      "Select user": "वापरकर्ता निवडा",
      FromDate: "या तारखेपासून",
      ToDate: "तारखेपर्यंत",
      UserName: "वापरकर्ता नाव",
      Date: "तारीख",
      MobileNumber: "मोबाईल नंबर",
      Message: "संदेश",
      Type: "प्रकार",
      MessageMedium: "संदेश माध्यम",
      Submit: "सबमिट करा",
    },
    keySettingScreen: {
      "Key Setting": "की सेटिंग",
      Key: "की",
      Value: "value",
      Secure: "सुरक्षित",
      Update: "अपडेट",
    },
    addCategoryScreen: {
      "Add Category": "श्रेणी जोडा",
      Category: "श्रेणी",
      CategoryName: "श्रेणीचे नाव",
      Edit: "संपादित करा",
      Delete: "हटवा",
      Cancel: "रद्द करा",
      Add: "जोडा",
      Update: "अपडेट करा",
      "Category is required": "श्रेणी आवश्यक आहे.",
    },
    addServicesScreen: {
      "Add Services": "सेवा जोडा",
      "Select Image": "प्रतिमा निवडा",
      ServicesDetails: "सेवा तपशील",
      Document: "दस्तऐवज",
      Category: "श्रेणी",
      Service: "सेवा(सेवा तपशील)",
      Link: "वर्ग",
      Edit: "संपादित करा",
      Delete: "हटवा",
      ServicesLink: "सेवा लिंक",
      SelectImage: "प्रतिमा निवडा",
      CategoryName: "श्रेणीचे नाव",
      Cancel: "रद्द करा",
      Add: "जोडा",
      Update: "अपडेट करा",
      "Service details is required": "सेवा तपशील आवश्यक आहे",
      "Select category": "श्रेणी निवडा",
    },
    addTestimonialScreen: {
      "Add Testimonial": "प्रशंसापत्र जोडा",
      "Select Image": "प्रतिमा निवडा",
      Document: "दस्तऐवज",
      Name: "जोडा",
      Profession: "व्यवसाय",
      Description: "वर्णन",
      Video: "व्हिडिओ",
      Edit: "संपादित करा",
      Delete: "हटवा",
      AddName: "नाव जोडा",
      AddProfession: "व्यवसाय जोडा",
      AddDescription: "वर्णन जोडा",
      Cancel: "रद्द करा",
      Add: "जोडा",
      Update: "अपडेट करा",
      "Testimonial name is required": "प्रशस्तिपत्रांचे नाव आवश्यक आहे.",
      "Click here to upload Image": "प्रतिमा अपलोड करण्यासाठी येथे क्लिक करा",
      "Select Video": "व्हिडिओ निवडा",
      "Click here to upload Video": "व्हिडिओ अपलोड करण्यासाठी येथे क्लिक करा",
    },
    applicantReportScreen: {
      "Applicant Report": "अर्जदार अहवाल",
      "Selection Criteria": "निवड निकष",
      Document: "दस्तऐवज",
      Honorifics: "सन्मानार्थी",
      ApplicantName: "अर्जदाराचे नाव",
      MobileNumber: "मोबाईल नंबर",
      Pincode: "पिनकोड",
      Address: "पत्ता",
      Religion: "धर्म",
    },
    applicantPersonalRegistrationScreen: {
      "Applicant Personal Details": "अर्जदाराचे वैयक्तिक तपशील",
      "Select user": "अर्जदाराचे वैयक्तिक तपशील",
      "Select state": "राज्य निवडा",
      "Select district": "जिल्हा निवडा",
      "Select taluka": "तालुका निवडा",
      "Select Gender": "लिंग निवडा",
      "Select honorifics": "सन्मानार्थी निवडा",
      UserName: "वापरकर्ता नाव",
      Honorifics: "सन्मानार्थी",
      FirstName: "प्रथम नाव",
      MiddleName: "मधले नाव",
      LastName: "आडनाव",
      GenderName: "लिंग नाव",
      Address: "पत्ता",
      Village: "गाव",
      StateName: "राज्याचे नाव",
      DistrictName: "जिल्ह्याचे नाव",
      TalukaName: "तालुक्याचे नाव",
      PinCode: "पिनकोड",
      DOB: "जन्मतारीख",
      PlaceOfBirth: "जन्मस्थान",
      Age: "वय",
      MotherTongue: "मातृभाषा",
      FatherName: "वडिलांचे नाव",
      MotherName: "आईचे नाव",
      Cancel: "रद्द करा",
      Add: "जोडा",
      Update: "अपडेट करा",
      "PinCode Number is required": "पिनकोड क्रमांक आवश्यक आहे.",
    },
    schemeReportScreen: {
      "Scheme Report": "योजना अहवाल",
      "Scheme Name": "योजनेचे नाव",
      Link: "लिंक",
      "Scheme Type": "योजनेचा प्रकार",
      Description: "वर्णन",
      "Required Documents": "लागणारी कागतपत्रे ",
      Document: "दस्तऐवज",
      "Eligibility Criteria": "पात्रता निकष",
    },
    applicantParentRegistrationScreen: {
      "Applicant Parent Details": "अर्जदार पालक तपशील",
      "Select user": "वापरकर्ता निवडा",
      UserName: "वापरकर्ता नाव",
      Address: "पत्ता",
      Village: "गाव",
      StateName: "राज्याचे नाव",
      DistrictName: "जिल्ह्याचे नाव",
      TalukaName: "तालुक्याचे नाव",
      PinCode: "पिनकोड",
      SelectMaritalStatus: "वैवाहिक स्थिती निवडा",
      SpouseName: "पतीचे नाव",
      EmailID: "ईमेल आयडी",
      ContactNumber: "संपर्क क्रमांक",
      AdharNumber: "आधार क्रमांक",
      PanNumber: "पॅन नंबर",
      VoterIDCardNumber: "मतदार ओळखपत्र क्रमांक",
      Other: "इतर",
      MaritalStatus: "वैवाहिक स्थिती",
      Cancel: "रद्द करा",
      Add: "जोडा",
      Update: "अपडेट करा",
      "Select state": "राज्य निवडा",
      "Select district": "जिल्हा निवडा",
      "Select taluka": "तालुका निवडा",
      "Adhar number is required": "आधार क्रमांक आवश्यक आहे.",
    },
    applicantFamilyRegistrationScreen: {
      "Applicant Family Details": "अर्जदार कौटुंबिक तपशील",
      "Select user": "वापरकर्ता निवडा",
      UserName: "वापरकर्ता नाव",
      NameOfHeadOfFamily: "कुटुंब प्रमुखाचे नाव",
      ReligionName: "धर्माचे नाव",
      ReligionOther: "धर्म ",
      EarningSource: "कमाईचा स्रोत",
      Range: "श्रेणी",
      FamilyMembers: "कुटुंब सदस्य",
      SpentAmount: "खर्च केलेली रक्कम",
      RationCardNumber: "रेशनकार्ड क्रमांक",
      Cancel: "रद्द करा",
      Add: "जोडा",
      Update: "अपडेट करा",
      "Ration Card Number is required": "रेशन कार्ड नंबर आवश्यक आहे.",
      "Select Income Range": "उत्पन्न श्रेणी निवडा",
      "Select Religion": "धर्म निवडा",
    },
    applicantDocumentScreen: {
      "Applicant Document": "अर्जदार दस्तऐवज",
      "Select user": "वापरकर्ता निवडा",
      "Select Image": "प्रतिमा निवडा",
      UserName: "वापरकर्ता नाव",
      "Applicant Photo": "अर्जदाराचा फोटो",
      "AdharCard Front": " आधार कार्ड पुढील फोटो ",
      "AdharCard Back": "आधार कार्ड मागील फोटो",
      "Pan Card": "पॅन कार्ड",
      "VoterID Card": "मतदार ओळखपत्र",
      "Ration Card": "रेशन कार्ड",
      Other: "इतर",
      Cancel: "रद्द करा",
      Submit: "सबमिट करा",
    },
    applicantRegistrationScreen: {
      "Applicant Registration": " अर्जदार नोंदणी",
      "APPLICANT PERSONAL DETAILS": "अर्जदार वैयक्तिक तपशील",
      "APPLICANT PARENT DETAILS": "अर्जदार पालक तपशील",
      "APPLICANT FAMILY DETAILS": "अर्जदार कुटुंब तपशील",
      "APPLICANT DOCUMENT": "अर्जदार दस्तऐवज",
    },
    addSchemeDetailsScreen: {
      "Add Scheme": "योजना जोडा",
      "Select Image": "प्रतिमा निवडा",
      "Select category": "श्रेणी निवडा",
      "Select service": "सेवा निवडा",
      "Select State": "राज्य निवडा",
      "Select Religion": "धर्म निवडा",
      "Select Gender": "लिंग निवडा",
      "Select Income Range": "उत्पन्न श्रेणी निवडा",
      SchemeName: "योजनेचे नाव",
      Description: "वर्णन",
      ServicesDetails: "सेवा तपशील",
      Link: "लिंक",
      CategoryName: "श्रेणीचे नाव",
      SchemeType: "योजना प्रकार",
      FromAge: "वयापासून",
      ToAge: "वयापर्यंत",
      Education: "शिक्षण",
      ReligionName: "धर्माचे नाव",
      GenderName: "लिंग ",
      Range: "श्रेणी",
      SubReligion: "उपधर्म",
      AreaZone: "क्षेत्र झोन",
      StateName: "राज्याचे नाव",
      OrphanedChildren: "अनाथ मुले",
      Crippled: "अपंग",
      MinorLandHolders: "अल्पं भूधारक",
      LandLessShetMajur: "भूमिहीन शेतमजूर",
      DestituteMen: "  निराधार पुरुष",
      DestituteWomen: "निराधार महिला",
      NaturalDeath: "नैसर्गिक मृत्यू",
      SubDuction: "घटस्फोट",
      HortiCulture: "बागायती",
      ArableFarmLand: "जिरायती शेतजमीन",
      Beneficiary: "लाभार्थी",
      DivorcedButNotReceivingAlinony:
        "घटस्फोटित परंतु अलिनोनी प्राप्त होत नाही",
      AllCategoryOfDisabledDisease: "अपंग रोग सर्व श्रेणी",
      TheWidow: "विधवा",
      BankPassbook: "बँक पासबुक",
      AdharCard: "आधार कार्ड",
      RationCard: "रेशन कार्ड",
      RahivasiDhakhla: "राहिवासी धाखला",
      PovertyLineCertificate: "दारिद्र्यरेषेचे प्रमाणपत्र",
      SaatBaaraUtara: "सातबारा उतारा",
      landrecorddocument: "जमीन रेकॉर्ड दस्तऐवज",
      FamilyPlaningCertificate: "कुटुंब नियोजन प्रमाणपत्र",
      Benefit: "लाभ",
      Department: "विभाग",
      DivorcedStatus: "घटस्फोटित स्थिती",
      TypesofDiseases: "रोगांचे प्रकार",
      AgriculturalLand: "शेतजमीन",
      PovertyCertificate: "दारिद्रय प्रमाणपत्र",
      MinorLandowners: "अल्पभूधारक",
      "8A": "८अ ",
      SelectImage: "प्रतिमा निवडा",
      "Additional Information": "अतिरिक्त माहिती",
      "Additional Information 1": "अतिरिक्त माहिती 1",
      "Additional Information 2": "अतिरिक्त माहिती 2",
      "Additional Information 3": "अतिरिक्त माहिती 3",
      Cancel: "रद्द करा",
      Add: "जोडा",
      Update: "अपडेट करा",
      "Select tag": "टॅग निवडा",
      OnlineOffline: "ऑनलाइन ऑफलाइन",
    },
    dashboardScreen: {
      Dashboard: "डॅशबोर्ड",
      TotalSchemes: "एकूण योजना",
      AllUsers: "सर्व वापरकर्ते",
      UserConnects: "वापरकर्त्याला जोडते",
      TotalBenefits: "एकूण लाभ",
      TopTrendingCategories: " टॉप ट्रेंडिंग श्रेणी",
      ThisWeekTrendingCategorie: "या आठवड्यातील ट्रेंडिंग श्रेणी",
      TotalUserReport: "एकूण वापरकर्ता अहवाल",
    },
    mainMenuList: {
      Scheme: "योजना",
      "User Registration": "वापरकर्ता नोंदणी",
      "Applicant Registration": "अर्जदार नोंदणी",
      "Question Bank": "प्रश्न पेढी",
      Dashboard: "डॅशबोर्ड",
      Logout: "बाहेर पडणे",
      Master: "मास्टर",
      Report: "अहवाल",
      Setting: "सेटिंग",
      "AI File Upload": "एआय फाइल अपलोड",
    },
    subMenuList: {
      "Add Scheme": "योजना जोडा",
      "Scheme Report": "योजना अहवाल",
      "Add Applicant": "अर्जदार जोडा",
      "Applicant Report": "अर्जदार अहवाल",
      "User Report": "वापरकर्ता अहवाल",
      "Sms Report": "एसएमएस  अहवाल",
      "Key Setting": "की सेटिंग",
      "Add Category": "श्रेणी जोडा",
      "Add Services": "सेवा जोडा",
      "Add Testimonial": "प्रशंसापत्र जोडा",
      "Add Question": "प्रश्न जोडा",
      "Add Answer": "उत्तर जोडा",
      "Message Setting": "संदेश सेटिंग",
      "SMS Setting": "एसएमएस सेटिंग",
      "Redirect User Report": "पुनर्निर्देशित वापरकर्ते अहवाल",
      "Added Answer Report": "उत्तर जोडलेला अहवाल",
    },
    accountMenu: {
      Profile: "प्रोफाइल",
      Logout: "बाहेर पडणे",
      "Change Password": "पासवर्ड बदला",
    },
    loginScreen: {
      "Welcome back!": " ग्राम कनेक्ट मध्ये आपले स्वागत आहे!",
      "Please enter your details": "साइन इन करा",
      MobileNumber: "मोबाईल नंबर",
      Password: "पासवर्ड",
      "Forgot password?": "पासवर्ड विसरलात?",
      "Sign In": "साइन इन",
      "Enter phone number": "मोबाईल नंबर येथे टाका",
      "Enter password": "पासवर्ड टाका",
      "Don't have an account?": "खाते नाही?",
      "Sign Up": "साइन अप करा",
      "Phone number is required": "मोबाईल नंबर आवश्यक आहे.",
      "Password is required": "पासवर्ड आवश्यक आहे",
    },
    forgotPassword: {
      "Forgot Password?": "पासवर्ड विसरलात?",
      "Return to Login": "लॉगिन वर परत या",
      "Do you want to login?": "तुम्हाला लॉगिन करायचे आहे का?",
      "No worries, we'll send you rest instructions.":
        " काळजी करू नका, आम्ही तुम्हाला उर्वरित सूचना पाठवू.",
      MobileNumber: "मोबाईल नंबर",
      PROCEED: "पुढे जा",
      "Mobile Number is required": "मोबाईल नंबर आवश्यक आहे",
    },
    signUpScreen: {
      "Create an account": "खाते तयार करा",
      "Please enter your details": "कृपया आपले तपशील येते भरा",
      "User Name": "वापरकर्ता नाव",
      "Mobile Number": "मोबाईल नंबर",
      "WhatsApp Number(Optional)": "व्हॉट्सॲप नंबर(पर्यायी)",
      "Email ID(Optional)": "ईमेल आयडी(पर्यायी)",
      Address: "पत्ता",
      Password: "पासवर्ड",
      "Already have an account?": "या पूर्वी खाते होते का ?",
      "Sign in": "साइन इन करा",
      Add: "माहिती भरा ",
      Reset: "रीसेट करा",
      "UserName is required": "वापरकर्तानाव आवश्यक आहे.",
      "Mobile number is required": "मोबाइल नंबर आवश्यक आहे.",
    },
    category: {
      Categories: "श्रेणी",
      "Agriculture & Environment": "शेती आणि पर्यावरण",
      "Banking & Financial Services": "बँकिंग आणि वित्तीय सेवा",
      "Business & Entrepreneurship": "व्यवसाय आणि उद्योजकता",
      "Education & Learning": "शिक्षण आणि शिकणे",
      "Health & Wellness": "आरोग्य आणि निरोगीपणा",
      "Housing & Shelter": "गृहनिर्माण आणि निवारा",
      Technology: "तंत्रज्ञान",
      "Skills & Employment": "कल्याण  आणि रोजगार",
      "Social Welfare": "समाज कल्याण",
      "Women & Child": "महिला आणि मूल",
    },
    Schemes: {
      Schemes: "योजना",
    },
    CustomerDashboardScreen: {
      Dashboard: "डॅशबोर्ड",
      SchemeBenifit: "योजनेचा लाभ ",
      ApplicantStatus: "अर्जदाराची स्थिती",
      "Applicant Personal Detail Status": "अर्जदार वैयक्तिक तपशील स्थिती",
      "Applicant Parent Detail Status": "अर्जदार पालक तपशील स्थिती",
      "Applicant Family Detail Status": "अर्जदार कुटुंब तपशील स्थिती",
    },
    AddQuestion: {
      "Add Question": "प्रश्न जोडा",
      Delete: "हटवा",
      Edit: "सुधारणा ",
      Question: "प्रश्न",
      Cancel: "रद्द करा",
      Add: "जोडा",
      Update: "अपडेट करा",
      "Select tag": "टॅग निवडा",
    },
    AddAnswer: {
      "Add User Details": "वापरकर्त्याचा तपशील जोडा",
      "Select User": "वापरकर्ता निवडा",
      Remark: "टिप्पणी",
      Yes: "होय",
      No: "नाही",
      Submit: "सबमिट करा",
      Update: "अपडेट करा",
      Previous: "मागे ",
      Next: "पुढे ",
    },
    AddedAnswerReport: {
      "Added Answer Report": "उत्तर जोडलेला अहवाल",
      "Select user": "वापरकर्ता निवडा",
      FromDate: "या तारखेपासून",
      ToDate: "तारखेपर्यंत",
      UserName: "	वापरकर्ता नाव",
      Answer: "उत्तर",
      Question: "प्रश्न",
      Remark: "टिप्पणी",
      Submit: "सबमिट करा",
    },
    indexscreen: {
      "Select Honorifics": "सन्मानार्थी निवडा",
    },
    AIFileUploadScreen: {
      "AI File Upload": "एआय फाइल अपलोड",
      "Upload File": "अपलोड फाइल",
      "Choose Files": "फाइल निवडा",
      Reset: "रीसेट करा",
    },
  },
};
