import React, { useState, useEffect } from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Grid } from '@mui/material';
 
import { CustomDialogTitle } from 'src/component/uiElements/withoutform/customDialogBox';
import { ColumnDef } from '@tanstack/table-core';
import { ImageView, srNo } from 'src/component/uiElements/table/reactTableView';
import ReactTable from 'src/component/uiElements/table/reactTableNew';
import { FileResponseProps } from './useAddScheme';


type ActionProps = {
  isOpen: boolean;
  title: string;
  request: FileResponseProps[];

  negative_button_click: (value: boolean) => void;
};

export function ViewDocument({
  isOpen,
  title,
  request,

  negative_button_click,
}: ActionProps) {
  const [maxWidth] = React.useState<DialogProps['maxWidth']>('sm');
  const [open, setOpen] = useState(isOpen);
  const handle_close = () => {
    setOpen(false);
    negative_button_click(false);
  };

  if (!isOpen) return null;

  return (
    <Dialog
      fullWidth
      maxWidth={maxWidth}
      open={open}
      onClose={() => {
        setOpen(false);
        negative_button_click(false);
      }}
    >
      <CustomDialogTitle id="customized-dialog-title" onClose={handle_close}>
        {title}
      </CustomDialogTitle>
      <DialogContent>
        <OfflineEventActionPopUp request={request} />
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={6}></Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

type ActionPopUpProps = {
  request: FileResponseProps[];
};
const OfflineEventActionPopUp = ({ request }: ActionPopUpProps) => {
  const totalCount = 0;
  const takeCount = 10;
  const [result, setResult] = useState<FileResponseProps[]>([]);
  const [columns, setColumns] = useState<ColumnDef<FileResponseProps>[]>([]);

  const create_column = () => {
    var column_list = [];
    column_list.push({
      header: '#',
      accessorKey: 'ImageMasterID',
      cell: ({ row, table }: any) => srNo(row, table),
    });

    column_list.push({
      header: 'View',
      accessorKey: 'ImagePath',
      cell: ({ row }: any) => ImageView(row?.original?.ImagePath),
    });

    column_list.push({
      header: 'ImageName',
      accessorKey: 'ImageName',
    });

    setColumns(column_list);
  };

  useEffect(() => {
    create_column();
    setResult(request);
  }, []);

  return (
    <Grid item xs={12}>
      <ReactTable
        requiredGlobalFilter={true}
        columns={columns}
        data={result}
        count={totalCount}
        rowCountPerPage={takeCount}
      />
    </Grid>
  );
};
