import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type AddSMSSettingProps = {
  SMSMasterID: number;
  APIName: string;
  MethodType: string;
  HeaderJson: string;
  SMSString: string;
  MethodTypeWhatsApp: string;
  HeaderJsonWhatsApp: string;
  WhatsAppURL: string;
  BalanceURL: string;
  Message: string;
};

type InitialStateProps = {
  settingList: AddSMSSettingProps;
};

const initialState: InitialStateProps = {
  settingList: {
    SMSMasterID: 0,
    APIName: '',
    MethodType: '',
    HeaderJson: '',
    SMSString: '',
    MethodTypeWhatsApp: '',
    HeaderJsonWhatsApp: '',
    WhatsAppURL: '',
    BalanceURL: '',
    Message: '',
  },
};

const SMSSettingSlice = createSlice({
  name: 'SMSSettingSlice',
  initialState,
  reducers: {
    add_sms_setting_list: (
      state,
      action: PayloadAction<AddSMSSettingProps>
    ) => {
      state.settingList = action.payload;
    },
    clear_sms_setting_list: (state) => {
      state.settingList = {
        SMSMasterID: 0,
        APIName: '',
        MethodType: '',
        HeaderJson: '',
        SMSString: '',
        MethodTypeWhatsApp: '',
        HeaderJsonWhatsApp: '',
        WhatsAppURL: '',
        BalanceURL: '',
        Message: '',
      };
    },
  },
});
export default SMSSettingSlice.reducer;
export const { add_sms_setting_list, clear_sms_setting_list } =
  SMSSettingSlice.actions;
