import { useEffect, useState } from "react";
import { ToastError } from "src/component/uiElements/toast";
import { SchemeReportByID as SchemeReportByID } from "src/component/api/addScheme/addScheme";
import { LOGOUT } from "src/component/helper/constants";

export type FileResponseProps = {
    FilePath: string;
    FileName: string;
    Extension: string;
    ImageMasterID: number;
};

export type TestimonialProps = {
    Link: string;
    ImageMasterID: number;
    Name: string;
    Profession: string;
    Description: string;
    FileList: FileResponseProps[];
};

export type SchemeProps = {
    list: any;
    Link: string;
    SchemeMasterID: number;
    SchemeName: string;
    Description: string;
    FileList: FileResponseProps[];
};

const UseViewScheme = (schemeMasterID: number) => {
    const [schemeList, setSchemeList] = useState<SchemeProps[]>([]);

    useEffect(() => {
        SelectBySchemeMasterID();
    }, [schemeMasterID]);

    const SelectBySchemeMasterID = async () => {

        let request = JSON.stringify(schemeMasterID);
        setSchemeList([]);

        const response = await SchemeReportByID(request);

        if (!response?.isAuthorized) LOGOUT();
        if (!response?.status) {
            let failResult = JSON.parse(response?.data!);
            ToastError(failResult.Message);
            return;
        }
        var data = JSON.parse(response?.data!);
        setSchemeList(data.list);

    };

    return {
        SelectBySchemeMasterID,
        schemeList

    };
};

export default UseViewScheme;
