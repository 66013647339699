import React, { useState, useEffect } from "react";
import { Button, Box, styled, BadgeProps, Badge, Link } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";

const Navbar: React.FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false);
  const { t } = useTranslation("public");

  const [nestedAnchorEl, setNestedAnchorEl] = useState<null | HTMLElement>(
    null
  );

  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };
  return (
    <>
      <Box className="header">
        <Box>
          <img src="logo.png" alt="Logo" />
        </Box>
        <Box className="loginmenubox">
          <Link className="login" href="/login">
            Login
          </Link>
          <Button onClick={toggleNav} className="navButton">
            {isNavOpen ? (
              <CloseIcon className="burgermenu" />
            ) : (
              <MenuIcon className="burgermenu" />
            )}
          </Button>
        </Box>
      </Box>

      <Box className={`overlay ${isNavOpen ? "open" : ""}`}>
        <Button onClick={toggleNav} className="closebtn">
          <CloseIcon className="closebtnicon" />
        </Button>
        <Box className="overlay-content">
          <Link href="/Home">Home</Link>
          <Link href="/about">About Us</Link>
          <Link href="/Categories">Category</Link>
          <Link href="/schemes">Schemes</Link>
          <Link href="/login">Login</Link>
        </Box>
      </Box>
    </>
  );
};

export default Navbar;
