import { Grid, Typography, Box, Button, Container } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export default function About() {
  return (
    <>
      <Box className="innercontent">
        <Box className="pagehead">
          <Typography className="innerpagehead">About Us</Typography>
        </Box>

        <Container fixed sx={{ marginBottom: "70px" }}>
          <Grid container spacing={3} sx={{ marginTop: "30px" }}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box className="contentbg">
                <Typography variant="h6" className="subheading">
                  ग्रामऊर्जा संस्थे बद्दल
                </Typography>
                <Typography>
                  ग्रामऊर्जा फाउंडेशन हि सामाजिक संस्था ग्रामविकासाचा अनुभव
                  असणाऱ्या सुशिक्षित तरुणांनी सुरु केलेली संस्था आहे. मागील 4
                  वर्षापासून बीड व धाराशिव जिल्ह्यामध्ये शिक्षण, रोजगार, उद्योग
                  व शासकीय योजना यावर काम करत आहे.
                </Typography>

                <Typography variant="h6" className="subheading">
                  ग्राम कनेक्ट
                </Typography>

                <Typography>
                  ग्रामीण भागातील गरीब व गरजू व्यक्तीपर्यंत शासकीय योजना
                  पोहचवण्यासाठी ग्रामऊर्जा फाउंडेशन या सामाजिक संस्थेच्या
                  माध्यमातून या उपक्रमाची सुरवात करण्यात आली आहे. या मध्ये मोफत
                  शासकीय योजनांची माहिती तसेच विविध योजनांना आर्ज करण्यसाठी मदत
                  केली जाणार आहे.
                </Typography>

                <Typography variant="h6" className="subheading">
                  उद्देश
                </Typography>

                <Typography>
                  ग्रामीण भागातील गरीब व गरजू व्यक्ती पर्यंत जास्तीत जास्त
                  योजनांची माहिती पोचावी. व गरजू लाभार्थ्यांना योजनांचा लाभ
                  मिळावा.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <img src="about.jpg" />
            </Grid>
          </Grid>
        </Container>

        <Box className="whatweoffer">
          <Typography className="whatwehead">
            <span>Know more</span> about
          </Typography>
          <Typography className="whatwehead">what we offer.</Typography>
          <Button
            className="offerbutton"
            variant="outlined"
            endIcon={<ArrowForwardIcon />}
          >
            Our Offerings
          </Button>
        </Box>
      </Box>
    </>
  );
}
