import { PostAPI , PostAPIWithoutRequestAndKeyData} from "src/component/api/helper/apiHelper";

export const Login = async (request: string) => {
  return PostAPI(request, "Credential/Login");
};
export const VerifyOTP = async (request: string) => {
  return PostAPI(request, "Credential/VerifyOTP");
};

export const SchemeDataForPublicTemplate = async () => {
  return PostAPIWithoutRequestAndKeyData( 'SchemeMaster/SchemeDataForPublicTemplate');
};

export const ContactDetails = async () => {
  return PostAPIWithoutRequestAndKeyData( 'Credential/ContactDetails');
};