import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import { CustomSecondaryButton } from "src/component/uiElements/buttons/secondaryButton";
import CustomCategoriesCard from "src/component/uiElements/card/categoriesCard";
import CustomSchemesCard from "src/component/uiElements/card/schemesCard";

export default function SubCategories() {
  return (
    <>
      <Paper
        sx={{
          pt: "40px",
          boxShadow: "0 0 0 0",
          m: "-8px -8px 0px -8px",
          bgcolor: "#fff",
        }}
      >
        <Grid
          container
          sx={{
            mt: "40px",
            bgcolor: "#fff",
            p: "60px",
            borderRadius: "0px",
            justifyContent: "center",
            "@media (max-width: 599px)": {
              mt: "20px",
            },
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              color: "#222",
              fontSize: "20px",
              fontWeight: "400",
            }}
          >
            List schemes by category Agriculture & Environment
          </Typography>
        </Grid>
      </Paper>

      <Paper
        sx={{
          justifyContent: "center",
          boxShadow: "none",
          p: "40px 24px",
          background: "#fff",
          m: "-20px -8px 60px -8px",
          display: "flex",
        }}
      >
        <Grid container spacing={3} sx={{ justifyContent: "center" }}>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <Grid container spacing={3} sx={{ justifyContent: "center" }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <CustomSchemesCard
                  linkTo=""
                  title={"Indira Gandhi Utkrisht Chatravriti Yojana"}
                  description={
                    "The scholarship under the Indira Gandhi Utkrisht Chatravriti Yojana shall be awarded to the Ten Toppers each from the merit list of 10+2 Arts, Science and Commerce, supplied by the HP. Board of School Education Dharamshala, and to the Ten Toppers from the merit lists of B.A./ B.Sc./ B.Com."
                  }
                  buttonLink={"./GramUrja (1).pdf"}
                />

                <Divider
                  sx={{
                    backgroundImage:
                      "linear-gradient(90deg, #FAFAFA 12.5%, #DDDDDD 50.82%, #FAFAFA 86%)",
                    height: "1px",
                    border: "none",
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <CustomSchemesCard
                  linkTo=""
                  title={"Malaviya Mission- Teacher Training Programme"}
                  description={
                    "UGC Malaviya Mission Teacher Training Programme 2023: Education Minister Dharmendra Pradhan launched the Malaviya Mission - Teachers Training Programme by the University Grants Commission (UGC) on the occasion of Teachers' Day."
                  }
                  buttonLink={"/"}
                />

                <Divider
                  sx={{
                    backgroundImage:
                      "linear-gradient(90deg, #FAFAFA 12.5%, #DDDDDD 50.82%, #FAFAFA 86%)",
                    height: "1px",
                    border: "none",
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <CustomSchemesCard
                  linkTo=""
                  title={"Mai Bhago Vidya Scheme"}
                  description={
                    "Under this State Govt. scheme, free bicycles are provided to girl students from 9th to 12th class studying in Government schools to encourage the enrolment of girl students in schools and to reduce the school dropout rate among girl students."
                  }
                  buttonLink={"/"}
                />

                <Divider
                  sx={{
                    backgroundImage:
                      "linear-gradient(90deg, #FAFAFA 12.5%, #DDDDDD 50.82%, #FAFAFA 86%)",
                    height: "1px",
                    border: "none",
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <CustomSchemesCard
                  linkTo=""
                  title={"Bebe Nanki Laadli Beti Kalyan Scheme"}
                  description={
                    "This was launched under 13th Finance Commission component for 'measures to improve sex ratio' in the State from 2011-12. The main objective of the scheme is to curb female feticide and to provide better education to girls."
                  }
                  buttonLink={"/"}
                />

                <Divider
                  sx={{
                    backgroundImage:
                      "linear-gradient(90deg, #FAFAFA 12.5%, #DDDDDD 50.82%, #FAFAFA 86%)",
                    height: "1px",
                    border: "none",
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <CustomSchemesCard
                  linkTo=""
                  title={"Mai Bhago Vidya Scheme"}
                  description={
                    "Under this State Govt. scheme, free bicycles are provided to girl students from 9th to 12th class studying in Government schools to encourage the enrolment of girl students in schools and to reduce the school dropout rate among girl students."
                  }
                  buttonLink={"/"}
                />

                <Divider
                  sx={{
                    backgroundImage:
                      "linear-gradient(90deg, #FAFAFA 12.5%, #DDDDDD 50.82%, #FAFAFA 86%)",
                    height: "1px",
                    border: "none",
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <CustomSchemesCard
                  linkTo=""
                  title={"Bebe Nanki Laadli Beti Kalyan Scheme"}
                  description={
                    "This was launched under 13th Finance Commission component for 'measures to improve sex ratio' in the State from 2011-12. The main objective of the scheme is to curb female feticide and to provide better education to girls."
                  }
                  buttonLink={"/"}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
