import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import AuthLayout from "src/component/navigation/publicNavbar";
import About from "src/pages/outerPages/about/about";
import Categories from "src/pages/outerPages/categories/categories";
import ViewSchemas from "src/pages/outerPages/ViewSchema/viewSchemas";
import Schemes from "src/pages/outerPages/schemes/schemes";
import SubCategories from "src/pages/outerPages/subCategories/subCategories";

const LoginScreen = lazy(
  () => import("src/pages/outerPages/login/loginScreen")
);
const ForgetPasswordScreen = lazy(
  () => import("src/pages/outerPages/forgotPassword/forgotPasswordScreen")
);
const HomeScreen = lazy(() => import("src/pages/outerPages/home/home"));
const SignUpScreen = lazy(
  () => import("src/pages/outerPages/sign_up/signUpScreen")
);
const SignUpwithSchemeScreen = lazy(
  () => import("src/pages/outerPages/signup_withscheme/signUpwithSchemeScreen")
);
const SchemeDataScreen = lazy(
  () => import("src/pages/outerPages/schemeData/schemeDataScreen")
);
const TestimonialPage = lazy(
  () => import("src/pages/outerPages/outsideTestimonial/TestimonialPage")
);

const AuthRoute: React.FC = () => {
  return (
    <AuthLayout>
      <Suspense>
        <Routes>
          <Route path="/Sign_up" element={<SignUpScreen />} />
          <Route
            path="/SignUp_WithScheme"
            element={<SignUpwithSchemeScreen />}
          />
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/forgot_password" element={<ForgetPasswordScreen />} />
          <Route path="/home" element={<HomeScreen />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/viewSchemas" element={<ViewSchemas />} />
          <Route path="/about" element={<About />} />
          <Route path="/subCategories" element={<SubCategories />} />
          <Route path="/schemes" element={<Schemes />} />
          <Route path="/scheme_data" element={<SchemeDataScreen />} />
          <Route path="/testimonials" element={<TestimonialPage />} />
          <Route path="/" element={<HomeScreen />} />
        </Routes>
      </Suspense>
    </AuthLayout>
  );
};

export default AuthRoute;
