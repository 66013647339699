import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { SchemeDataForPublicTemplate as SELECT } from "src/component/api/public/login";
import { LOGOUT } from "src/component/helper/constants";
import { ViewDocument } from "src/pages/innerPages/add_scheme/viewDocument";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export type FileResponseProps = {
  FilePath: string;
  FileName: string;
  Extension: string;
  ImageMasterID: number;
};
type ResponseProps = {
  list: SchemeProps[];
  ReportPath: string;
  Count: number;
  SchemeMasterID: number;
  SchemeName: string;
  Description: string;
  Message: string;
  FileList: FileResponseProps[];
};

export type SchemeProps = {
  list: any;
  Link: string;
  SchemeMasterID: number;
  SchemeName: string;
  Description: string;
  TagDescription: string;
  RequiredDocuments: string;
  OnlineOffline: string;
  FileList: FileResponseProps[];
};
const Schemes = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("Schemes");
  const [isFileModalShow, setIsFileModalShow] = useState(false);
  const [FilePathList, setFilesList] = useState<FileResponseProps[]>([]);
  const [list, setList] = useState<SchemeProps[]>([]);
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const response = await SELECT();
      if (!response?.isAuthorized) LOGOUT();
      if (!response?.status) {
        return;
      }
      let result: ResponseProps = JSON.parse(response?.data!);
      setList(result as unknown as SchemeProps[]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleProfileClick = (SchemeMasterID: number) => {
    navigate("/SignUp_WithScheme", { state: { SchemeMasterID } });
  };

  const handleShowDocument = (fileList: FileResponseProps[]) => {
    setFilesList([]);
    setIsFileModalShow(true);
    setFilesList(fileList);
  };

  return (
    <>
      <Box className="innercontent">
        <Box className="pagehead">
          <Typography className="innerpagehead">Schemes</Typography>
        </Box>
        <Container fixed sx={{ marginBottom: "70px" }}>
          <Grid container spacing={3} sx={{ marginTop: "30px" }}>
            {list.map((scheme: SchemeProps, index) => (
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box className="allschemes">
                  <Box className="schemedetails">
                    <Typography
                      color="textPrimary"
                      component="div"
                      sx={{
                        fontWeight: "bold",
                        textAlign: "justify",
                        fontSize: "20px",
                        marginBottom: "10px",
                      }}
                    >
                      <a
                        href={scheme.Link}
                        onClick={(e) => {
                          e.preventDefault();
                          handleProfileClick(scheme.SchemeMasterID);
                        }}
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "#4169E1", textDecoration: "none" }}
                      >
                        {scheme.SchemeName}
                      </a>
                    </Typography>
                    <Typography>{scheme.Description}</Typography>
                    <Typography variant="h5">
                      लागणारी कागतपत्रे : {scheme.RequiredDocuments}
                    </Typography>
                    <Typography variant="h5">
                      पात्रता निकष : {scheme.TagDescription}
                    </Typography>
                    <Typography variant="h5">
                      योजनेचा प्रकार : {scheme.OnlineOffline}
                    </Typography>
                    <Typography variant="h5">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          className="showdocs"
                          onClick={() => handleShowDocument(scheme.FileList)}
                        >
                          {t("Show Document")}
                        </Button>
                      </div>
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
        {isFileModalShow && (
          <ViewDocument
            isOpen={isFileModalShow}
            title="View Image"
            request={FilePathList}
            negative_button_click={(value) => {
              setIsFileModalShow(false);
            }}
          />
        )}

        <Box className="whatweoffer">
          <Typography className="whatwehead">
            <span>Know more</span> about
          </Typography>
          <Typography className="whatwehead">what we offer.</Typography>
          <Button
            className="offerbutton"
            variant="outlined"
            endIcon={<ArrowForwardIcon />}
          >
            Our Offerings
          </Button>
        </Box>
      </Box>
    </>
  );
};
export default Schemes;
