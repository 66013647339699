import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  TextFieldProps,
  Toolbar,
  TablePagination,
} from "@mui/material";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import "@tanstack/table-core";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  FilterFn,
  SortingFn,
  sortingFns,
  SortingState,
  PaginationState,
} from "@tanstack/react-table";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import {
  RankingInfo,
  rankItem,
  compareItems,
} from "@tanstack/match-sorter-utils";
import LinearProgress from "@mui/material/LinearProgress";
import React, { useEffect, useState } from "react";
import TablePaginationActions from "./tablePaginationActions";
import styles from "./Table.module.css";

export type paginationProps = {
  pageIndex: number;
  pageSize: number;
};

type TableProps<TData> = {
  data: TData[];
  columns: ColumnDef<TData>[];
  count?: number;
  currentIndex?: number;
  rowCountPerPage?: number;
  loading?: boolean;
  completed?: number;
  requiredGlobalFilter?: Boolean;
  requiredPagination?: Boolean;
  requiredRowSelect?: Boolean;
  getPageCallback?: (returnValues: paginationProps) => void;
  getSearchCallback?: (SearchText: string) => void;
  onVideoClick?: (videoPath: string) => void;
  // getSortCallback?: (value: any) => void;
};

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

// const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
//   let dir = 0;

//   // Only sort by rank if the column has ranking information
//   if (rowA.columnFiltersMeta[columnId]) {
//     dir = compareItems(
//       rowA.columnFiltersMeta[columnId]?.itemRank!,
//       rowB.columnFiltersMeta[columnId]?.itemRank!
//     );
//   }

//   // Provide an alphanumeric fallback for when the item ranks are equal
//   return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
// };

// A debounced input react component
const DebouncedInput = ({
  isDisplay,
  value: initialValue,
  onChange,
  debounce = 300,
  ...props
}: {
  isDisplay: Boolean | undefined;
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<TextFieldProps, "onChange">) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  const reset = () => {
    setValue("");
  };

  if (!isDisplay) {
    return null;
  }

  return (
    <Toolbar>
      <SearchIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
      <TextField
        {...props}
        autoComplete="off"
        placeholder="Search"
        value={value}
        variant="standard"
        onChange={(e) => setValue(e.target.value)}
        className={styles.customSearch}
      />
      <IconButton aria-label="delete" size="large" onClick={() => reset()}>
        <ClearIcon />
      </IconButton>
    </Toolbar>
  );
};

const ReactTable = ({
  data,
  columns,
  count,
  currentIndex,
  rowCountPerPage,
  loading,
  completed,
  requiredGlobalFilter,
  requiredPagination,
  requiredRowSelect,
  getPageCallback,
  getSearchCallback,
  onVideoClick,
}: //getSortCallback,
TableProps<any>): JSX.Element => {
  const [globalFilter, setGlobalFilter] = useState("");
  const [page, setPage] = useState(
    currentIndex !== undefined ? currentIndex : 0
  );
  const [rowsPerPage, setRowsPerPage] = useState(
    rowCountPerPage !== undefined ? rowCountPerPage : 10
  );

  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: page,
      pageSize: rowsPerPage,
    });

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const [sorting, setSorting] = React.useState<SortingState>([]);

  const table = useReactTable<any>({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    manualPagination: true,
    autoResetPageIndex: false,
    state: {
      globalFilter,
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onPaginationChange: setPagination,
    getPaginationRowModel: getPaginationRowModel(),
  });

  // if (getSearchCallback !== undefined) {
  //   getSearchCallback(globalFilter);
  // }

  useEffect(() => {
    setPage(currentIndex !== undefined ? currentIndex : 0);
    setPagination({
      pageIndex: currentIndex !== undefined ? currentIndex : 0,
      pageSize: rowsPerPage,
    });

    setRowsPerPage(rowCountPerPage !== undefined ? rowCountPerPage : 10);
  }, [data]);

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
    setPagination({ pageIndex: newPage, pageSize: rowsPerPage });
    //callback to our compo

    if (getPageCallback) {
      let returnvalue: paginationProps = {
        pageIndex: newPage,
        pageSize: rowsPerPage,
      };
      getPageCallback(returnvalue);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let pageSize = +parseInt(event.target.value);
    setPage(0);
    setRowsPerPage(pageSize);
    setPagination({ pageIndex: 0, pageSize: pageSize });
    // callback to our comp
    if (getPageCallback) {
      let returnvalue: paginationProps = { pageIndex: 0, pageSize: pageSize };
      getPageCallback(returnvalue);
    }
  };

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          width: "100%",
          mt: 1,
          borderRadius: "12px",
          bgcolor: "white",
          boxShadow:
            "0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)",
          p: "14px",
        }}
      >
        {loading && (
          <LinearProgress
            variant="indeterminate"
            color="secondary"
            value={completed}
          />
        )}
        <DebouncedInput
          isDisplay={requiredGlobalFilter}
          value={globalFilter ?? ""}
          onChange={(value) => setGlobalFilter(String(value))}
          className="p-2 font-lg shadow border border-block"
          placeholder="Search"
        />

        <Table aria-label="table" size="small">
          <TableHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableCell
                      key={header.id}
                      colSpan={header.colSpan}
                      sx={{
                        backgroundColor: "#EBF6FF",
                        fontWeight: "bold",
                        borderBottom: "none",
                        border: "0.5px solid #CEE9FF",
                      }}
                    >
                      {header.isPlaceholder ? null : (
                        <div
                          {...{
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}

                          {{
                            asc: (
                              <ArrowDownward
                                color="primary"
                                fontSize="inherit"
                              />
                            ),
                            desc: (
                              <ArrowUpward color="primary" fontSize="inherit" />
                            ),
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>

          <TableBody>
            {table.getRowModel().rows.map((row) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                  sx={{
                    "&:not(last-child) td, &:not(last-child) th": {
                      border: "0.5px solid #F6FAFD",
                    },
                    "&:nth-child(even)": {
                      backgroundColor: "#F4FAFF",
                    },
                  }}
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <TableCell key={cell.id} component="th">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {requiredPagination === false ? null : (
          <TablePagination
            component={"div"}
            rowsPerPageOptions={[10, 25, 50, 100, { value: -1, label: "All" }]}
            count={count === undefined ? data.length : count}
            rowsPerPage={rowsPerPage}
            page={page}
            className={styles.peginationOverflow}
            // SelectProps={{
            //   inputProps: {
            //     'aria-label': 'rows per page',
            //   },
            //   native: true,
            // }}
            getItemAriaLabel={(type) =>
              type === "first"
                ? "Go to first page"
                : type === "last"
                ? "Go to last page"
                : type === "next"
                ? "Go to next page"
                : "Go to previous page"
            }
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} of ${count}`
            }
            labelRowsPerPage="Rows per page:"
            sx={{
              "& .MuiTablePagination-selectLabel": {
                m: "0 -1px",
              },
              "& .MuiTablePagination-displayedRows": {
                m: "0 -1px",
              },
              zIndex: 2,
            }}
            onPageChange={(_: any, newPage: number) =>
              handleChangePage(newPage)
            }
            onRowsPerPageChange={handleChangeRowsPerPage}
            // colSpan={3}
            ActionsComponent={TablePaginationActions}
          />
        )}
      </TableContainer>
    </>
  );
};

export default ReactTable;
