import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import CustomCategoriesCard from "src/component/uiElements/card/categoriesCard";
import {
  Paper,
  Box,
  Typography,
  Grid,
  Button,
  Link,
  Container,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export default function Categories() {
  const { t } = useTranslation("category");
  const navigate = useNavigate();
  const handleByCategoryType = (categoryMasterID: number) => {
    navigate("/scheme_data", { state: { categoryMasterID } });
  };

  return (
    <>
      <Box className="innercontent">
        <Box className="pagehead">
          <Typography className="innerpagehead">Category</Typography>
        </Box>

        <Container fixed sx={{ marginBottom: "70px" }}>
          <Grid container spacing={3} sx={{ marginTop: "30px" }}>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <CustomCategoriesCard
                title={t("Agriculture & Environment")}
                categoryMasterID={13}
                ButtonName={"Know more"}
                onClick={(categoryMasterID) =>
                  handleByCategoryType(categoryMasterID)
                }
                imageUrl={"indian-farmer.png"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <CustomCategoriesCard
                title={t("Banking & Financial Services")}
                categoryMasterID={14}
                ButtonName={"Know more"}
                onClick={(categoryMasterID) =>
                  handleByCategoryType(categoryMasterID)
                }
                imageUrl={"heap-wealth.png"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <CustomCategoriesCard
                title={t("Business & Entrepreneurship")}
                categoryMasterID={15}
                ButtonName={"Know more"}
                onClick={(categoryMasterID) =>
                  handleByCategoryType(categoryMasterID)
                }
                imageUrl={"men-shaking-hands-successful-business.png"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <CustomCategoriesCard
                title={t("Education & Learning")}
                categoryMasterID={16}
                ButtonName={"Know more"}
                onClick={(categoryMasterID) =>
                  handleByCategoryType(categoryMasterID)
                }
                imageUrl={"young-adult-achievement-graduation.png "}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <CustomCategoriesCard
                title={t("Health & Wellness")}
                categoryMasterID={17}
                ButtonName={"Know more"}
                onClick={(categoryMasterID) =>
                  handleByCategoryType(categoryMasterID)
                }
                imageUrl={"health & wellness.png"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <CustomCategoriesCard
                title={t("Housing & Shelter")}
                categoryMasterID={18}
                ButtonName={"Know more"}
                onClick={(categoryMasterID) =>
                  handleByCategoryType(categoryMasterID)
                }
                imageUrl={"sunset-old-fashioned-cottage-tranquil.png"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <CustomCategoriesCard
                title={t("Technology")}
                categoryMasterID={19}
                ButtonName={"Know more"}
                onClick={(categoryMasterID) =>
                  handleByCategoryType(categoryMasterID)
                }
                imageUrl={"boy-wearing-pair-vr-glasses-with-word.png"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <CustomCategoriesCard
                title={t("Skills & Employment")}
                categoryMasterID={20}
                ButtonName={"Know more"}
                onClick={(categoryMasterID) =>
                  handleByCategoryType(categoryMasterID)
                }
                imageUrl={"group-happy-workers-construction-site.png"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <CustomCategoriesCard
                title={t("Social Welfare")}
                categoryMasterID={21}
                ButtonName={"Know more"}
                onClick={(categoryMasterID) =>
                  handleByCategoryType(categoryMasterID)
                }
                imageUrl={"no-racism.png"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <CustomCategoriesCard
                title={t("Women & Child")}
                categoryMasterID={22}
                ButtonName={"Know more"}
                onClick={(categoryMasterID) =>
                  handleByCategoryType(categoryMasterID)
                }
                imageUrl={"medium-shot-mother-holding-cute-baby.png"}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box className="whatweoffer">
        <Typography className="whatwehead">
          <span>Know more</span> about
        </Typography>
        <Typography className="whatwehead">what we offer.</Typography>
        <Button
          className="offerbutton"
          variant="outlined"
          endIcon={<ArrowForwardIcon />}
        >
          Our Offerings
        </Button>
      </Box>
    </>
  );
}
