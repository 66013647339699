import {
  toast,
  ToastPosition,
  ToastOptions,
  ToastContainerProps,
} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

let position: ToastPosition = "bottom-right";
let options: ToastOptions = {
  position: position,
  pauseOnHover: true,
  draggable: true,
  autoClose: 15 * 1000,
  progress: undefined,
};

let configOption: ToastContainerProps = {
  autoClose: 15 * 1000,
  draggable: true, 
  position: "bottom-right" as ToastPosition,
};

export const ToastError = (message: string) => {
  const customId = message;
  if (toast.isActive(customId)) {
    toast.update(customId);
    return;
  }

  toast.dismiss();
  toast.error(message, { ...options, toastId: customId });
};

export const ToastSuccess = (message: string) => {
  const customId = message;
  if (toast.isActive(customId)) {
    toast.update(customId);
    return;
  }
  //toast.configure(configOption);
  toast.dismiss();
  toast.success(message, { ...options, toastId: customId });
};

export const ToastCloseAll = () => {
  toast.dismiss();
};
