import { Box, Grid, Link, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useLocation } from "react-router-dom";

export default function Footer() {
  const location = useLocation();

  if (location.pathname === "/testimonials") {
    return null;
  }
  return (
    <>
      <Box className="footer">
        <Grid container>
          <Grid item xs={12} sm={12} md={2} lg={2} sx={{ textAlign: "center" }}>
            <Box>
              <img className="footlogo" src="logo.png" alt="Footer Logo" />
            </Box>
            <Box className="socialgroup">
              <img src="facebook.svg" alt="Facebook" className="socialimg" />
              <img src="linkedin.svg" alt="LinkedIn" className="socialimg" />
              <img src="instagram.svg" alt="Instagram" className="socialimg" />
              <img src="youtube.svg" alt="YouTube" className="socialimg" />
              <img src="twitter.svg" alt="Twitter" className="socialimg" />
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Box sx={{ marginBottom: "30px" }}>
              <Typography className="addresshead">Head Office :</Typography>
              <Typography className="address">
                Office No. 210, Indulal Complex, Lal Bahadur Shastri Road,
                Sadashiv Peth, Pune, Maharashtra - 411030
              </Typography>
            </Box>
            <Box>
              <Typography className="addresshead">Regional Office :</Typography>
              <Typography className="address">
                Vidyut Nagar, Beed Road, Morewadi Ambajogai, Maharashtra -
                431517
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Typography className="footlinkhead">Useful Links</Typography>
            <Box className="footlinkgroup">
              <Link className="footlinks">
                <CheckCircleIcon className="footbullet" /> Home
              </Link>
              <Link className="footlinks">
                <CheckCircleIcon className="footbullet" /> About Us
              </Link>
              <Link className="footlinks">
                <CheckCircleIcon className="footbullet" /> Category
              </Link>
              <Link className="footlinks">
                <CheckCircleIcon className="footbullet" /> Schemes
              </Link>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Typography className="footlinkhead">Policies</Typography>
            <Box className="footlinkgroup">
              <Link className="footlinks">
                <CheckCircleIcon className="footbullet" /> Terms & Conditions
              </Link>
              <Link className="footlinks">
                <CheckCircleIcon className="footbullet" /> Policy
              </Link>
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ textAlign: "center" }}>
          <Typography className="copyright">
            Copyright © 2024 GramConnect Foundation. | All rights reserved
          </Typography>
        </Box>
      </Box>
    </>
  );
}
