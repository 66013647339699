export const IsValidString = (inputString: string) => {
  if (inputString === undefined) return false;

  if (inputString === null) return false;

  let input = String(inputString);

  return input.trim().length > 0;
};

export const IsValidMobileNumber = (inputString: string) => {
  if (!IsValidString(inputString)) return false;

  return inputString.length === 10;
};

export const IsValidPassword = (inputString: string) => {
  if (!IsValidString(inputString)) return false;

  return inputString.length > 3;
};

export const IsValidStatusCode = (input: number) => {
  if (input === undefined) return false;

  return input !== null;
};
